import React from "react";
import { Link } from "react-router-dom";
import Footer from "./commonComponents/footer";
import Header from "./commonComponents/header";

function TermCondition() {
  return (
    <>
      <Header />
      <section className="breadcrumb__area">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__content">
                <h1
                  className="breadcrumb__title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.15s"
                >
                  Our Term & Conditions
                </h1>
                <p
                  className="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.3s"
                >
                  <Link to="/">Home</Link>
                  <span>Term & Conditions</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/imgs/hero/overlay.png"
          alt="overlay"
          className="overlay"
        />
      </section>
      <div className="terms-conditions-area pt-70 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 m-auto ">
              <div className="terms-conditions">
                <div className="section-title">
                  <h2
                    className="sec-sub-title text-center wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.2s"
                  >
                    Information &amp; Notices
                  </h2>
                  <h3
                    className="sec-title text-center wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    Default Terms &amp; Conditions
                  </h3>
                </div>

                <div className="conditions-content mt-5">
                  <h3>
                    1. Staudigl Perfumery And Health Shop Caress Your Soul
                  </h3>
                  <p>
                    But I must explain to you how all this mistaken idea of
                    denouncing pleasure and praising pain was born and I will
                    give you a complete account of the system, and expound the
                    actual teachings of the great explorer of the.
                  </p>
                  <p>
                    truth, the master-builder of human happiness. No one
                    rejects, dislikes, or avoids pleasure itself, ecause it is
                    pleasure, but because those who do not know how to pursue
                    pleasure rationally encounter consequences that are
                    extremely painful. Nor again is there anyone who loves or
                    pursues or desires to.
                  </p>

                  <ul>
                    <li>
                      <i className="fa fa-check"></i>
                      It has survived not only five centuries, but also the leap
                      into electronic typesetting.
                    </li>
                    <li>
                      <i className="fa fa-check"></i>
                      Contrary to popular belief, Lorem Ipsum is not simply
                      random text.
                    </li>
                    <li>
                      <i className="fa fa-check"></i>
                      The point of using Lorem Ipsum is that it has a
                      more-or-less normal distribution of letters.
                    </li>
                  </ul>
                </div>

                <div className="conditions-content">
                  <h3>2. Available For Electrical Services</h3>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in some
                    form, by injected humour, or randomised words which don't
                    look even slightly believable. If you are going to use a
                    passage of Lorem Ipsum, you need to be sure there isn't
                    anything embarrassing hidden in the middle of text. All the
                    Lorem Ipsum generators on the Internet.
                  </p>
                </div>

                <div className="conditions-content">
                  <h3>3. Rate & Write Reviews</h3>
                  <p>
                    But I must explain to you how all this mistaken idea of
                    denouncing pleasure and praising pain was born and I will
                    give you a complete account of the system, and expound the
                    actual teachings of the great explorer of the.
                  </p>
                </div>

                <div className="conditions-content">
                  <h3>4. Available Tests</h3>
                  <p>
                    Fugit, sed quia consequuntur magni dolores eos qui ratione
                    voluptatem sequi nesciunt. Neque pouam est, qui dolorem
                    ipsum quia dolor sit amet, consectetur, adipisci velit, sed
                    quia non numquam eius modi tempora incidunt.
                  </p>
                  <p>
                    On the other hand, we denounce whteous indignation and
                    dislike men wh beguiled and demoralized er hand,
                  </p>

                  <ul>
                    <li>
                      <i className="fa fa-check"></i>
                      Nunc, mauris ut in vestibulum. Consectetur phasellus
                      ultrices fusce nibh justo, venenatis, amet.
                    </li>
                    <li>
                      <i className="fa fa-check"></i>
                      Web design and development very creative to dosomething.
                    </li>
                    <li>
                      <i className="fa fa-check"></i>
                      Phasellus ultrices fusce nibh venenatis, amet to all
                      design and development.
                    </li>
                  </ul>
                </div>

                <div className="conditions-content five">
                  <h3>5. Processing of personal Reviews</h3>
                  <p>
                    At vero eos et accusamus et iusto odio dignissimos ducimus
                    qui blanditiis praesentium voluptatum eleniti corrupti quos
                    dolores et quas molestias excepturi sint occaecati
                    cupiditate non provident, similique sunt in culpa officia
                    deserunt mollitia animi, id est laborum et dolorum fuga. Et
                    harum quidem rerum facilis.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default TermCondition;
