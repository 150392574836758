import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "./commonComponents/footer";
import Header from "./commonComponents/header";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, FreeMode } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

function Team() {
  useEffect(() => {
    // use jquery here if you must, this way the component is loaded
    //and the dom matches whats in react (or should)
  }, []);
  return (
    <>
      <Header />
      <section className="breadcrumb__area">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__content">
                <h1
                  className="breadcrumb__title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  Our Team
                </h1>
                <p
                  className="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <Link to="/">Home</Link>
                  <span>Our Team</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/imgs/hero/overlay.png"
          alt="overlay"
          className="overlay"
        />
      </section>
      <section className="team__area pt-70">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 text-center">
              <div className="">
                <h2
                  className="sec-sub-title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  Our Skilled Team
                </h2>
                <h3
                  className="sec-title-2 wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  Meet The Leaders
                </h3>
              </div>
              <div className="team__slider-wrap">
                <div className="swiper team__slider">
                  <div className="swiper-wrapper">
                    <Swiper
                      slidesPerView={1}
                      spaceBetween={30}
                      freeMode={true}
                      centeredSlides={true}
                      breakpoints={{
                        640: {
                          slidesPerView: 1,
                        },
                        768: {
                          slidesPerView: 2,
                        },
                        1024: {
                          slidesPerView: 3,
                        },
                        1300: {
                          slidesPerView: 4,
                        },
                      }}
                      autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                      }}
                      pagination={{
                        clickable: true,
                      }}
                      modules={[Autoplay, Pagination, FreeMode]}
                      className="mySwiper"
                    >
                      <SwiperSlide>
                        <div
                          className="team__slide wow animate__animated animate__fadeInUp"
                          data-wow-delay="0.2s"
                        >
                          <div className="team__info">
                            <a href="#">
                              <img src="assets/imgs/team/1.png" alt="Client" />
                            </a>
                            <ul className="team__social">
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-facebook-f"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-twitter"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-instagram"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-pinterest-p"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <a href="#">
                            <h4 className="team__name">Dipok Hassa Kollol</h4>
                            <h5 className="team__role mb-5">Developer</h5>
                          </a>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div
                          className="team__slide wow animate__animated animate__fadeInUp"
                          data-wow-delay="0.4s"
                        >
                          <div className="team__info">
                            <a href="#">
                              <img src="assets/imgs/team/2.png" alt="Client" />
                            </a>
                            <ul className="team__social">
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-facebook-f"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-twitter"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-instagram"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-pinterest-p"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <a href="#">
                            <h4 className="team__name">Jonathon Fedrik</h4>
                            <h5 className="team__role mb-5">Manager</h5>
                          </a>
                        </div>
                      </SwiperSlide>{" "}
                      <SwiperSlide>
                        <div
                          className="team__slide wow animate__animated animate__fadeInUp"
                          data-wow-delay="0.6s"
                        >
                          <div className="team__info">
                            <a href="#">
                              <img src="assets/imgs/team/3.png" alt="Client" />
                            </a>
                            <ul className="team__social">
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-facebook-f"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-twitter"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-instagram"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-pinterest-p"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <a href="#">
                            <h4 className="team__name">Nirob Aronno</h4>
                            <h5 className="team__role mb-5">Designer</h5>
                          </a>
                        </div>
                      </SwiperSlide>{" "}
                      <SwiperSlide>
                        <div
                          className="team__slide wow animate__animated animate__fadeInUp"
                          data-wow-delay="0.8s"
                        >
                          <div className="team__info">
                            <a href="#">
                              <img src="assets/imgs/team/4.png" alt="Client" />
                            </a>
                            <ul className="team__social">
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-facebook-f"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-twitter"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-instagram"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-pinterest-p"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <a href="#">
                            <h4 className="team__name">Samrat Devnath </h4>
                            <h5 className="team__role mb-5">UI/UX Designer</h5>
                          </a>
                        </div>{" "}
                      </SwiperSlide>{" "}
                      <SwiperSlide>
                        <div
                          className="team__slide wow animate__animated animate__fadeInUp"
                          data-wow-delay="0.3s"
                        >
                          <div className="team__info">
                            <a href="#">
                              <img src="assets/imgs/team/1.png" alt="Client" />
                            </a>
                            <ul className="team__social">
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-facebook-f"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-twitter"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-instagram"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-pinterest-p"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <a href="#">
                            <h4 className="team__name">Dipok Hassa Kollol</h4>
                            <h5 className="team__role mb-5">Developer</h5>
                          </a>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div
                          className="team__slide wow animate__animated animate__fadeInUp"
                          data-wow-delay="0.5s"
                        >
                          <div className="team__info">
                            <a href="#">
                              <img src="assets/imgs/team/2.png" alt="Client" />
                            </a>
                            <ul className="team__social">
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-facebook-f"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-twitter"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-instagram"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-pinterest-p"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <a href="#">
                            <h4 className="team__name">Nirob Aronno</h4>
                            <h5 className="team__role mb-5">UI/UX Designer</h5>
                          </a>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div
                          className="team__slide wow animate__animated animate__fadeInUp"
                          data-wow-delay="0.3s"
                        >
                          <div className="team__info">
                            <a href="#">
                              <img src="assets/imgs/team/1.png" alt="Client" />
                            </a>
                            <ul className="team__social">
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-facebook-f"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-twitter"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-instagram"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-pinterest-p"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <a href="#">
                            <h4 className="team__name">Dipok Hassa Kollol</h4>
                            <h5 className="team__role mb-5">Developer</h5>
                          </a>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div
                          className="team__slide wow animate__animated animate__fadeInUp"
                          data-wow-delay="0.5s"
                        >
                          <div className="team__info">
                            <a href="#">
                              <img src="assets/imgs/team/2.png" alt="Client" />
                            </a>
                            <ul className="team__social">
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-facebook-f"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-twitter"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-instagram"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-pinterest-p"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <a href="#">
                            <h4 className="team__name">Nirob Aronno</h4>
                            <h5 className="team__role mb-5">UI/UX Designer</h5>
                          </a>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div
                          className="team__slide wow animate__animated animate__fadeInUp"
                          data-wow-delay="0.4s"
                        >
                          <div className="team__info">
                            <a href="#">
                              <img src="assets/imgs/team/2.png" alt="Client" />
                            </a>
                            <ul className="team__social">
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-facebook-f"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-twitter"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-instagram"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-pinterest-p"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <a href="#">
                            <h4 className="team__name">Jonathon Fedrik</h4>
                            <h5 className="team__role mb-5">Manager</h5>
                          </a>
                        </div>
                      </SwiperSlide>{" "}
                      <SwiperSlide>
                        <div
                          className="team__slide wow animate__animated animate__fadeInUp"
                          data-wow-delay="0.6s"
                        >
                          <div className="team__info">
                            <a href="#">
                              <img src="assets/imgs/team/3.png" alt="Client" />
                            </a>
                            <ul className="team__social">
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-facebook-f"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-twitter"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-instagram"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <i className="fa-brands fa-pinterest-p"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <a href="#">
                            <h4 className="team__name">Nirob Aronno</h4>
                            <h5 className="team__role mb-5">Designer</h5>
                          </a>
                        </div>
                      </SwiperSlide>
                    </Swiper>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="experience__area pt-130 pb-130">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
              <div className="experience__left">
                <h2
                  className="sec-sub-title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  Skills & Experiences
                </h2>
                <h3
                  className="sec-title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  People Compare To <br />
                  Our Team Leaders & <br /> <span>Their Skills.</span>
                </h3>
                <p
                  className="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.5s"
                >
                  There are many variations of passages of Lorem Ipsum
                  available, the majority have suffered alteration in some form,
                  by injected humour. randomised words which don't look even
                  slightly believable. It uses a dictionary of over 200 Latin
                  words, combined with{" "}
                </p>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
              <div className="experience__right">
                <div
                  className="experience__item wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.15s"
                >
                  <div className="exp-active" data-width="90" data-target="100">
                    Branding Designs
                  </div>
                </div>
                <div
                  className="experience__item wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.3s"
                >
                  <div className="exp-active" data-width="70" data-target="100">
                    Marketing
                  </div>
                </div>
                <div
                  className="experience__item wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.45s"
                >
                  <div className="exp-active" data-width="95" data-target="100">
                    Marketing
                  </div>
                </div>
                <div
                  className="experience__item wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.6s"
                >
                  <div className="exp-active" data-width="80" data-target="100">
                    Corporate Solution
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          className="footer-shape-2"
          src="assets/imgs/shapee/footer-shape.png"
          alt="image"
        />{" "}
      </section>
      <section className="process__area-2">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
              <div className="process__content-2 inherit-left">
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#workProcessModal2"
                >
                  <img src="assets/imgs/work-process/1.png" alt="Icon" />
                </button>
              </div>
            </div>

            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
              <div className="process__left-3 pt-130 pb-130">
                <h2
                  className="sec-sub-title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  Work Together
                </h2>
                <h3
                  className="sec-title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s "
                >
                  Works Process With <br />
                  Team <span>Leaders</span>
                </h3>
                <p
                  className="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.6s"
                >
                  There are many variations of passages of Lorem Ipsum
                  available, the majority have suffered alteration in some form,
                  by injected humour. randomised words which don't look even
                  slightly believable. It uses a dictionary of over 200 Latin
                  words, combined with{" "}
                </p>
                <Link
                  to="/contact-us"
                  className="cxu-btn-border-2 wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.7s"
                >
                  JOIN THE TEAM
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="modal fade modal__wkprocess"
        id="workProcessModal2"
        tabindex="-1"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <button className="modal-close" data-bs-dismiss="modal">
              <i className="fa-solid fa-xmark"></i>
            </button>
            <div className="modal-body">
              <iframe
                src="https://www.youtube.com/embed/CH50zuS8DD0"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <Footer />{" "}
    </>
  );
}

export default Team;
