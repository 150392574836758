import React from "react";
import { Link } from "react-router-dom";
import Footer from "./commonComponents/footer";
import Header from "./commonComponents/header";

function PortfolioDetail() {
  return (
    <>
      <Header />
      <section className="breadcrumb__area">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__content">
                <h1
                  className="breadcrumb__title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.15s"
                >
                  Our Project
                </h1>
                <p
                  className="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.3s"
                >
                  <Link to="/">Home</Link>
                  <span>Our Project</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/imgs/hero/overlay.png"
          alt="overlay"
          className="overlay"
        />
      </section>
      <section className="portfolio__area pt-70">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 text-center">
              <div className="">
                <h2
                  className="sec-sub-title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.15s"
                >
                  Our Portfolio
                </h2>
                <h3
                  className="sec-title-2 wow animate__animated animate__fadeInUp "
                  data-wow-delay="0.3s"
                >
                  Our Recent Projects
                </h3>
              </div>

              <div className="portfolio__items-3">
                <div
                  className="portfolio__item-3 wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.15s"
                >
                  <a href="#">
                    <img
                      src="assets/imgs/portfolio/1.png"
                      alt="Portfolio Thumb"
                      className="pf-thumb"
                    />
                    <div className="portfolio__info">
                      <h4 className="portfolio__title">Marketing</h4>
                      <h5 className="portfolio__cat">Social Media Marketing</h5>
                    </div>
                  </a>
                </div>
                <div
                  className="portfolio__item-3 wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.3s"
                >
                  <a href="#">
                    <img
                      src="assets/imgs/portfolio/2.png"
                      alt="Portfolio Thumb"
                      className="pf-thumb"
                    />
                    <div className="portfolio__info">
                      <h4 className="portfolio__title">Marketing</h4>
                      <h5 className="portfolio__cat">Social Media Marketing</h5>
                    </div>
                  </a>
                </div>
                <div
                  className="portfolio__item-3 wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.45s"
                >
                  <a href="#">
                    <img
                      src="assets/imgs/portfolio/3.png"
                      alt="Portfolio Thumb"
                      className="pf-thumb"
                    />
                    <div className="portfolio__info">
                      <h4 className="portfolio__title">Marketing</h4>
                      <h5 className="portfolio__cat">Social Media Marketing</h5>
                    </div>
                  </a>
                </div>

                <div
                  className="portfolio__item-3 wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.6s"
                >
                  <a href="#">
                    <img
                      src="assets/imgs/portfolio/4.png"
                      alt="Portfolio Thumb"
                      className="pf-thumb"
                    />
                    <div className="portfolio__info">
                      <h4 className="portfolio__title">Marketing</h4>
                      <h5 className="portfolio__cat">Social Media Marketing</h5>
                    </div>
                  </a>
                </div>

                <div
                  className="portfolio__item-3 wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.45s"
                >
                  <a href="#">
                    <img
                      src="assets/imgs/portfolio/1.png"
                      alt="Portfolio Thumb"
                      className="pf-thumb"
                    />
                    <div className="portfolio__info">
                      <h4 className="portfolio__title">Marketing</h4>
                      <h5 className="portfolio__cat">Social Media Marketing</h5>
                    </div>
                  </a>
                </div>
                <div
                  className="portfolio__item-3 wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.6s"
                >
                  <a href="#">
                    <img
                      src="assets/imgs/portfolio/2.png"
                      alt="Portfolio Thumb"
                      className="pf-thumb"
                    />
                    <div className="portfolio__info">
                      <h4 className="portfolio__title">Marketing</h4>
                      <h5 className="portfolio__cat">Social Media Marketing</h5>
                    </div>
                  </a>
                </div>
                <div
                  className="portfolio__item-3 wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.45s"
                >
                  <a href="#">
                    <img
                      src="assets/imgs/portfolio/3.png"
                      alt="Portfolio Thumb"
                      className="pf-thumb"
                    />
                    <div className="portfolio__info">
                      <h4 className="portfolio__title">Marketing</h4>
                      <h5 className="portfolio__cat">Social Media Marketing</h5>
                    </div>
                  </a>
                </div>

                <div
                  className="portfolio__item-3 wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.6s"
                >
                  <a href="#">
                    <img
                      src="assets/imgs/portfolio/4.png"
                      alt="Portfolio Thumb"
                      className="pf-thumb"
                    />
                    <div className="portfolio__info">
                      <h4 className="portfolio__title">Marketing</h4>
                      <h5 className="portfolio__cat">Social Media Marketing</h5>
                    </div>
                  </a>
                </div>
              </div>

              <div className="blog__pagination mt-4">
                <ul
                  className="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.9s"
                >
                  <li className="prev">
                    <i className="fa-solid fa-chevron-left"></i>
                  </li>
                  <li>01</li>
                  <li>02</li>
                  <li className="next">
                    <i className="fa-solid fa-chevron-right"></i>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      ]
      <Footer />
    </>
  );
}

export default PortfolioDetail;
