import React from "react";
import { Link } from "react-router-dom";
import Footer from "./commonComponents/footer";
import Header from "./commonComponents/header";
import { Provider, LikeButton } from "@lyket/react";

function BlogDetail() {
  return (
    <>
      <Header />
      <section className="breadcrumb__area">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__content">
                <h1
                  className="breadcrumb__title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  Recent Blog
                </h1>
                <p
                  className="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <Link to="/">HOME</Link> <span>Recent Blog</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/imgs/bg/cyber_banner_image.svg"
          alt="overlay"
          className="overlay"
        />
      </section>

      <section className="blog__detail-area pt-100">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="blog__inner-3">
                <div className="blog__items-3">
                  <article className="blog__item-3">
                    <Link to="">
                      <img src="assets/imgs/blog/4.jpg" alt="Blog Image" />
                    </Link>
                    <div className="blog__content-3">
                      <div className="blog__meta plr">
                        <div className="date">
                          <span>
                            <i className="fa-solid fa-calendar-days"></i> 22{" "}
                          </span>{" "}
                          JAN 2020
                        </div>
                        <ul>
                          <li>
                            <Link to="">
                              <i className="fa-regular fa-folder"></i> Apartment
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              <i className="fa-regular fa-user"></i> Nirob
                              Aronno
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <h2 className="blog__title-3 plr">
                        Incorporation is A Big Milestone for Your Business You
                        Need Considering
                      </h2>

                      <div className="blog__detail">
                        <div className="blog__detail-content">
                          <p>
                            Dimply dummy text of the printing and typesetting
                            industry. Lorem Ipsum has been the industry’s
                            standard dumy text ever since the 1500s, when an
                            unknown printer took a galley of type and scrambled
                            It is a long established fact that a reader will be
                            distracted by the readable content of a page when
                            looking at its layout. The point of using Lorem
                            Ipsum The man, who is in a stable condition in
                            hospital, has “potentially life-changing injuries”
                            after the overnight attack in Garvagh, County Lono
                            donderry. He was shot in the arms and legs.
                          </p>

                          <blockquote>
                            The intruders chased the girl in the house and
                            threatened her when she hid from them, according to
                            the PSNI Limavady Facebook page.
                          </blockquote>

                          <div className="cols-2">
                            <img src="assets/imgs/blog/14.png" alt="Image" />
                            <p>
                              Dimply dummy text of the printing and typesetting
                              industry. Lorem Ipsum has been the industry’s
                              standard dumy text ever since the 1500s, when an
                              unknown printer took a galley of type and
                              scrambled It is a long established fact that a
                              reader will be distrac
                            </p>
                          </div>
                          <p>
                            Dimply dummy text of the printing and typesetting
                            industry. Lorem Ipsum has been the industry’s
                            standard dumy text ever since the 1500s, when an
                            unknown printer took a galley of type and scrambled
                            It is a long established fact that a reader will be
                            distrac
                          </p>
                        </div>

                        <div className="feedback">
                          <div className="text">
                            <p>Is this content most useful?</p>
                          </div>
                          <div className="btns">
                            <Link className="like" to="">
                              <Provider
                                apiKey="acc0dbccce8e557db5ebbe6d605aaa"
                                theme={{
                                  colors: {
                                    text: "#1b2e59",
                                    primary: "#22c1c3",
                                    highlight: "#00aded",
                                    icon: "#00aded",
                                  },
                                }}
                              >
                                <LikeButton
                                  namespace="my-blog-post"
                                  id="how-to-beat-me-at-chess-10"
                                />
                              </Provider>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>

                  <div className="blog__tag-share">
                    <div className="blog__tags">
                      <p>tags:</p>
                      <ul>
                        <li>
                          <span>Corporate,</span>
                        </li>
                        <li>
                          <span>Business,</span>
                        </li>
                        <li>
                          <span>Finance</span>
                        </li>
                      </ul>
                    </div>
                    <div className="blog__share">
                      <p>
                        <i className="fa-solid fa-share-nodes"></i> Share
                      </p>
                      <ul className="header__social-2 share">
                        <li>
                          <Link to="">
                            <i className="fa-brands fa-facebook-f"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            <i className="fa-brands fa-twitter"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            <i className="fa-brands fa-instagram"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            <i className="fa-brands fa-pinterest-p"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="blog__prev-next">
                    <div className="blog__prev">
                      <Link to="">
                        <img src="assets/imgs/blog/5.png" alt="Image" />
                        <p>
                          <span>Previous Post</span> Company launches new
                          software channel
                        </p>
                      </Link>
                    </div>
                    <div className="blog__next">
                      <Link to="">
                        <p>
                          <span>Next Post</span> Reasons to the explain fast
                          business builder
                        </p>
                        <img src="assets/imgs/blog/5.png" alt="Image" />
                      </Link>
                    </div>
                  </div>

                  <div className="blog__author">
                    <div className="img">
                      <Link to="">
                        <img src="assets/imgs/team/1.jpg" alt="Author" />
                      </Link>
                    </div>
                    <div className="info">
                      <Link to="">
                        <h2 className="name">Jonathan Patawari</h2>
                      </Link>
                      <h3 className="role">Reporter, CNN & Instructor</h3>
                      <p>
                        I'm Nirob Aronno a UI Designer designed for creatives
                        and made for everyone. Take a course on Code, Design, or
                        Project . We are believing everyone should have the
                        opportunity.
                      </p>
                      <ul className="header__social-2 share">
                        <li>
                          <Link to="">
                            <i className="fa-brands fa-facebook-f"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            <i className="fa-brands fa-twitter"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            <i className="fa-brands fa-instagram"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            <i className="fa-brands fa-pinterest-p"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="blog__sidebar">
                  <div className="sidebar__widgets">
                    <div className="widget__search">
                      <form action="#">
                        <input
                          type="text"
                          name="s"
                          placeholder="Search Keywords"
                        />
                        <input
                          type="submit"
                          className="submit"
                          value="Search"
                        />
                      </form>
                    </div>
                    <div className="widget__category">
                      <h2 className="widget__title pb-3">Categories</h2>
                      <ul>
                        <li>
                          <Link to="">
                            Android App Development <span>(05)</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            iOS App Development <span>(05)</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            React App Development <span>(05)</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            Website/WebApp Development <span>(05)</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            SAAS, CMS, Admin Panel, CRMS <span>(05)</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            Data Science & Data Analysis <span>(05)</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            Artificial Intelligence & Machine Learning
                            <span>(05)</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            2D & 3D Games
                            <span>(05)</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            Blockchain, NFT, & Metavarse
                            <span>(05)</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            Technology Impact on Earth & Environment
                            <span>(05)</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="widget__rposts">
                      <h2 className="widget__title">Recent Posts</h2>

                      <div className="recent-items">
                        <div className="recent-item">
                          <div className="img">
                            <Link to="">
                              <img src="assets/imgs/blog/5.png" alt="Image" />
                            </Link>
                          </div>
                          <div className="info">
                            <Link to="">
                              <h2 className="title">
                                A wonderful serenity has taken possession
                              </h2>
                            </Link>
                            <p>28 April 2021</p>
                          </div>
                        </div>
                        <div className="recent-item">
                          <div className="img">
                            <Link to="">
                              <img src="assets/imgs/blog/8.png" alt="Image" />
                            </Link>
                          </div>
                          <div className="info">
                            <Link to="">
                              <h2 className="title">
                                Company launches new software channel
                              </h2>
                            </Link>
                            <p>28 April 2021</p>
                          </div>
                        </div>
                        <div className="recent-item">
                          <div className="img">
                            <Link to="">
                              <img src="assets/imgs/blog/6.png" alt="Image" />
                            </Link>
                          </div>
                          <div className="info">
                            <Link to="">
                              <h2 className="title">
                                A wonderful serenity has taken possession
                              </h2>
                            </Link>
                            <p>28 April 2021</p>
                          </div>
                        </div>
                        <div className="recent-item">
                          <div className="img">
                            <Link to="">
                              <img src="assets/imgs/blog/7.png" alt="Image" />
                            </Link>
                          </div>
                          <div className="info">
                            <Link to="">
                              <h2 className="title">
                                A wonderful serenity has taken possession
                              </h2>
                            </Link>
                            <p>28 April 2021</p>
                          </div>
                        </div>
                        <div className="recent-item">
                          <div className="img">
                            <Link to="">
                              <img src="assets/imgs/blog/8.png" alt="Image" />
                            </Link>
                          </div>
                          <div className="info">
                            <Link to="">
                              <h2 className="title">
                                Company launches new software channel
                              </h2>
                            </Link>
                            <p>28 April 2021</p>
                          </div>
                        </div>
                        <div className="recent-item">
                          <div className="img">
                            <Link to="">
                              <img src="assets/imgs/blog/9.png" alt="Image" />
                            </Link>
                          </div>
                          <div className="info">
                            <Link to="">
                              <h2 className="title">
                                Company launches new software channel
                              </h2>
                            </Link>
                            <p>28 April 2021</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="widget__tags">
                      <h2 className="widget__title">Popular Tages</h2>
                      <ul>
                        <li>
                          <Link to="">Business</a>
                        </li>
                        <li>
                          <Link to="">Corporate</a>
                        </li>
                        <li>
                          <Link to="">Financial</a>
                        </li>
                        <li>
                          <Link to="">Web Development</a>
                        </li>
                        <li>
                          <Link to="">UI/UX Research</a>
                        </li>
                        <li>
                          <Link to="">Branding</a>
                        </li>
                        <li>
                          <Link to="">Website</a>
                        </li>
                        <li>
                          <Link to="">Web Plans</a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default BlogDetail;
