import React from "react";
import { Link } from "react-router-dom";

import Footer from "../commonComponents/footer";
import Header from "../commonComponents/header";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, FreeMode } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

function AndroidServices() {
  return (
    <>
      <Header />
      <section className="breadcrumb__area">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__content">
                <h1
                  className="breadcrumb__title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  Android Development
                </h1>
                <p
                  className="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <Link to="/">Home</Link>
                  <Link to="/services">Services</Link>
                  <span>Android App Development</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/imgs/bg/cyber_banner_image.svg"
          alt="overlay"
          className="overlay"
        />
      </section>

      <section className="video__area about__area-2 pt-100 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
              <div className="video__left">
                <h2
                  className="sec-sub-title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  We Promise For
                </h2>
                <h3
                  className="sec-title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  Best Android App Development Services
                </h3>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
              <div
                className="video__right wow animate__animated animate__fadeInUp"
                data-wow-delay="0.5s"
              >
                <p>
                  We not only develop apps, but we also craft your success
                  roadmap. Android App Development is a vast paradigm of
                  possibilities that can employ various services on the way to
                  success. Being the tech pioneers we find it obligatory to
                  offer your business new-age android app development services
                </p>
              </div>
            </div>
          </div>

          <div className="col-xxl-12 mt-5">
            <div class="development_servicess_outer">
              <div className="row">
                <div className=" col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12  ">
                  <div className="development_box ">
                    <span className="ios_icon">
                      <img
                        src="assets/imgs/icons/price3d.png"
                        alt="Custom Android App Development"
                      />
                    </span>
                    <h3>Custom Android App Development</h3>
                    <p>
                      Your business must be distinct from others. Realizing the
                      same, We offers custom android app development services so
                      you may voice your brand in just the way you want. We
                      deliver the android apps that are high-performing and
                      scalable.
                    </p>
                  </div>
                </div>
                <div className=" col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12 mt-xxl-0 mt-xl-0 mt-lg-0 mt-md-0 mt-5 ">
                  <div className="development_box ">
                    <span className="ios_icon">
                      <img
                        src="assets/imgs/icons/code3d.png"
                        alt="Android UI/UX Design"
                      />
                    </span>
                    <h3>Android UI/UX Design</h3>
                    <p>
                      The world is running its fingers all over Android. At a
                      time where Android devices have made a home in every
                      house, it is vital to expand your business on android’s
                      digital spectrum. Our development team is highly skilled
                      in fashioning user-friendly yet high geared android apps.
                    </p>
                  </div>
                </div>
                <div className=" col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12 mt-xxl-0 mt-xl-0 mt-lg-0 mt-md-5 mt-5 ">
                  <div className="development_box ">
                    <span className="ios_icon">
                      <img
                        alt="Android Web-Based Apps  "
                        src="assets/imgs/icons/price3d.png"
                      />
                    </span>
                    <h3>Android Web-Based Apps </h3>
                    <p>
                      Your business deserves to be seen everywhere. Our highly
                      competent app developers have unmatchable skills in
                      developing custom Android web-based app development. These
                      solutions will help you in making your app more accessible
                      & scalable to your users.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-12 mt-xxl-0 mt-xl-0 mt-lg-0 mt-md-5 mt-5 ">
                  <div className="development_box ">
                    <span className="ios_icon">
                      <img
                        src="assets/imgs/icons/code3d.png"
                        alt="Multi-Platform Deployment"
                      />
                    </span>
                    <h3>Multi-Platform Deployment </h3>
                    <p>
                      Carrying the beam of a top android app development company
                      we specialize in deploying apps with seamless integration.
                      Your needs to establish your reign over any platform will
                      be taken care of by our expert android app development
                      team.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          className="footer-shape-2"
          src="assets/imgs/shapee/footer-shape.png"
          alt="image"
        />
      </section>
      <section className="about__area pt-100 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 d-flex justify-content-center align-items-center">
              <div className="">
                <img
                  src="assets/imgs/service/2mobiles.png"
                  alt="Image"
                  className="image android_img"
                />
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
              <div className="about__right   ">
                <div className="about__right-inner  ">
                  <h2
                    className="sec-sub-title wow animate__animated animate__fadeInUp "
                    data-wow-delay="0.3s"
                  >
                    Android App Development
                  </h2>
                  <h3
                    className="sec-title wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    The Present And Future Of Technology
                  </h3>
                  <p
                    className="wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    We provides everything you need to ideate, develop and
                    launch a robust android app. Ever since our inception, our
                    sole motive has been to offer the world a highly competent
                    and skilled team of android app developers that can keep up
                    with the latest updates and versions of today’s
                    revolutionalizing technology.
                    <br /> Have an Android App Idea? Develop your Android app
                    from just an idea to a revenue-generating reality. We is a
                    Trusted Mobile App Development Company with a global
                    presence with offices in India, the USA, UAE, and Africa.
                    Our 100+ experienced android app developers team operates as
                    a single dynamic unit to develop high-end mobile apps with a
                    futuristic vision and growth potential.
                  </p>
                  <ul
                    className="list-check wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    <li>Product Quality</li>
                    <li>On Demand Design</li>
                    <li>Corporate Relationship</li>
                    <li>Dedicated Support 24/7</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          className="footer-shape"
          src="assets/imgs/shapee/image.png"
          alt="image"
        />
      </section>
      <section className="about__area about__area-2 pt-100 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 d-flex justify-content-center align-items-center">
              <div className="about__right   ">
                <div className="about__right-inner manifesto_content">
                  <h2
                    className="sec-sub-title wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.2s"
                  >
                    For Every Project For Every Pocket
                  </h2>
                  <h3
                    className="sec-title wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    Built With Best Features & UX
                  </h3>
                  <p
                    className="wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    The agile development process constitutes basic principles
                    of Mobile Game app development such as programming, testing,
                    ideation, deployment, launch, and post-launch maintenance.
                    The agile development process constitutes basic principles
                    of Mobile Game app development such as programming, testing,
                    ideation, deployment, launch, and post-launch maintenance.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
              <div className="manifesto_rightcontent">
                <div className="manifesto_inner">
                  <h3>
                    <span>
                      <img
                        src="assets/imgs/icons/fixed-engagement.png"
                        alt="Fixed Engagement Model"
                      />
                    </span>{" "}
                    Fixed Engagement Model
                  </h3>
                  <p>
                    This engagement model is ideal for businesses that plan on
                    setting scope, budget, and delivery once the project is out
                    of the initial testing and analysis phase.{" "}
                  </p>
                </div>
                <div className="manifesto_inner">
                  <h3>
                    <span>
                      <img
                        src="assets/imgs/icons/fixed-engagement.png"
                        alt="Hourly Engagement Model"
                      />
                    </span>{" "}
                    Hourly Engagement Model{" "}
                  </h3>
                  <p>
                    An excellent strategy for improved risk management and
                    flexibility. A basic Agile methodology that can not burn a
                    hole in your pocket{" "}
                  </p>
                </div>
                <div className="manifesto_inner">
                  <h3>
                    <span>
                      <img
                        src="assets/imgs/icons/fixed-engagement.png"
                        alt="Hire Dedicated Engagement Model"
                      />
                    </span>
                    Hire Dedicated Engagement Model{" "}
                  </h3>
                  <p>
                    This paradigm works effectively for large projects since it
                    requires a specialized development team of experts. It
                    provides better control, flexibility, and cost.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          className="footer-shape"
          src="assets/imgs/shapee/image.png"
          alt="image"
        />
      </section>

      <section className="video__area pt-5">
        <div className="container">
          <div className="video__top">
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <div className="video__left">
                  <h2
                    className="sec-sub-title wow animate__animated animate__fadeInUp text-center"
                    data-wow-delay="0.2s"
                  >
                    One-Time Payment
                  </h2>
                  <h3
                    className="sec-title wow animate__animated animate__fadeInUp text-center"
                    data-wow-delay="0.4s"
                  >
                    Integration With Popular Apps
                  </h3>
                  <p>
                    We provides everything you need to ideate, develop and
                    launch a robust ios app. Ever since our inception, our sole
                    motive has been to offer the world a highly competent and
                    skilled team of ios app developers that can keep up with the
                    latest updates and versions of today’s revolutionalizing
                    technology. Ever since our inception, our sole motive has
                    been to offer the world a highly competent.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="integration my-5">
            <div className="container">
              <div className="content">
                <div className="img">
                  <img
                    src="assets/imgs/about/intg1.png"
                    alt=""
                    className="mt-30"
                  />
                </div>
                <div className="img">
                  <img
                    src="assets/imgs/about/intg5.png"
                    alt=""
                    className="mt-60"
                  />
                </div>
                <div className="img">
                  <img
                    src="assets/imgs/about/intg4.png"
                    alt=""
                    className="mt-20"
                  />
                </div>
                <div className="img">
                  <img
                    src="assets/imgs/about/intg3.png"
                    alt=""
                    className="mt-80"
                  />
                </div>
                <div className="img">
                  <img src="assets/imgs/about/intg2.png" alt="" />
                </div>
              </div>
            </div>
            <img
              src="assets/imgs/about/intg_back.png"
              alt=""
              className="intg-back"
            />
          </div>
        </div>
      </section>

      <section className="video__area about__area-2">
        <div className="screenshots style-4">
          <div className="screenshots-slider style-4">
            <div className="swiper-container">
              <div className="swiper-wrapper">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={30}
                  freeMode={true}
                  centeredSlides={true}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 3,
                    },
                    1024: {
                      slidesPerView: 4,
                    },
                    1300: {
                      slidesPerView: 5,
                    },
                  }}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Autoplay, Pagination, FreeMode]}
                  className="mySwiper"
                  loop={true}
                >
                  <SwiperSlide>
                    <div className="img">
                      <img src="assets/imgs/screenshots/1.png" alt="" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="img">
                      <img src="assets/imgs/screenshots/2.png" alt="" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="img">
                      <img src="assets/imgs/screenshots/3.jpg" alt="" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="img">
                      <img src="assets/imgs/screenshots/4.png" alt="" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="img">
                      <img src="assets/imgs/screenshots/5.png" alt="" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="img">
                      <img src="assets/imgs/screenshots/1.png" alt="" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="img">
                      <img src="assets/imgs/screenshots/2.png" alt="" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="img">
                      <img src="assets/imgs/screenshots/3.jpg" alt="" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="img">
                      <img src="assets/imgs/screenshots/4.png" alt="" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="img">
                      <img src="assets/imgs/screenshots/5.png" alt="" />
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
          <img
            src="assets/imgs/screenshots/hand.png"
            alt=""
            className="mob-hand"
          />
        </div>
      </section>

      <div
        className="process-area pt-100 pb-100"
        style={{ backgroundColor: "#e6eefb" }}
      >
        <div className="container">
          <div className="section-title">
            <h2
              className="sec-sub-title wow animate__animated animate__fadeInUp text-center"
              data-wow-delay="0.3s"
            >
              Work Process
            </h2>
            <h3
              className="sec-title wow animate__animated animate__fadeInUp text-center "
              data-wow-delay="0.4s"
            >
              Do Your Business The Easy Way
            </h3>
          </div>
          <div className="row justify-content-center mt-5">
            <div className="col-lg-4 col-md-6">
              <div className="process-card">
                <div className="process-img">
                  <img
                    className="process-1"
                    src="assets/imgs/svg/process-1.svg"
                    alt="image"
                  />
                </div>
                <h4>Setup Workframe</h4>
                <p>
                  Nor again is there anyone who loves or pues or desires to
                  obtain pain of itself, because it is pain, but because
                  occasionally
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="process-card">
                <div className="process-img">
                  <img
                    className="process-1"
                    src="assets/imgs/svg/process-2.svg"
                    alt="image"
                  />
                </div>
                <h4>Download & install</h4>
                <p>
                  Nor again is there anyone who loves or pues or desires to
                  obtain pain of itself, because it is pain, but because
                  occasionally
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="process-card">
                <div className="process-img">
                  <img
                    className="process-1"
                    src="assets/imgs/svg/process-3.svg"
                    alt="image"
                  />
                </div>
                <h4>Payment & Boost</h4>
                <p>
                  Nor again is there anyone who loves or pues or desires to
                  obtain pain of itself, because it is pain, but because
                  occasionally
                </p>
              </div>
            </div>
          </div>
        </div>

        <div data-cues="slideInLeft">
          <img
            className="process-shape-1 moveHorizontal
            "
            src="assets/imgs/svg/process-shape-1.png"
            alt="process-shape "
          />
        </div>
        <div data-cues="slideInLeft">
          <img
            className="process-shape-2 moveHorizontal
            "
            src="assets/imgs/svg/process-shape-2.png"
            alt="process-shape "
          />
          <img
            className="footer-shape"
            src="assets/imgs/shapee/image.png"
            alt="image"
          />
        </div>
      </div>
      <section className="video__area pt-100 pb-100">
        <div className="container">
          <div className="video__top">
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <div className="video__left">
                  <h2
                    className="sec-sub-title wow animate__animated animate__fadeInUp text-center"
                    data-wow-delay="0.2s"
                  >
                    Our Strategy
                  </h2>
                  <h3
                    className="sec-title wow animate__animated animate__fadeInUp text-center"
                    data-wow-delay="0.4s"
                  >
                    Our Agile Development Strategy
                  </h3>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, the majority have suffered alteration in some
                    form, by injected humour. randomised words which don't look
                    even slightly believable. It uses a dictionary of over 200
                    Latin words, combined with the majority have suffered
                    alteration in some form, by injected humour. randomised
                    words which don't look even slightly believable.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-12 d-flex justify-content-center my-3">
            <div
              className="video__wrap wow animate__animated animate__zoomIn"
              data-wow-delay="0.2s"
            >
              <img
                src="assets/imgs/service/powering-solution.png"
                alt="Service full image"
                className="w-75 mx-auto d-block moveVertical"
              />
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <Link
              to="/about-us"
              className="cxu-btn-border wow animate__animated animate__fadeInUp mt-5"
              data-wow-delay="0.4s"
              style={{ zIndex: "9" }}
            >
              Read More
            </Link>
          </div>
        </div>

        <div className="integration">
          <img
            src="assets/imgs/about/intg_back.png"
            alt=""
            className="intg-back"
          />
        </div>
      </section>

      {/* <section className="about__area  pt-100 pb-100">
        <div className="android_integration  padding-comman">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="reform_business_main">
                  <div className="col-md-3">
                    <div className="reform_box">
                      <div className="reform_content">
                        <span>01</span>
                        <h3>Android Smartphones</h3>
                        <p>
                          85% of the smartphones today are android, ask
                          yourself- is your business there? We offer development
                          services to deploy exceptionally secure, technically
                          advanced, and feature-packed apps for Android
                          Smartphones.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="reform_box">
                      <div className="reform_img">
                        <img
                          src="assets/imgs/service/faster-than-android.png"
                          alt="Android Smartphones"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="reform_box">
                      <div className="reform_content">
                        <span>01</span>
                        <h3>Android Smartphones</h3>
                        <p>
                          85% of the smartphones today are android, ask
                          yourself- is your business there? We offer development
                          services to deploy exceptionally secure, technically
                          advanced, and feature-packed apps for Android
                          Smartphones.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="reform_box">
                      <div className="reform_img">
                        <img
                          src="assets/imgs/service/user-experience.png"
                          alt="Android Smartphones"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="reform_box">
                      <div className="reform_img">
                        <img
                          src="assets/imgs/service/security.png"
                          alt="Android Smartphones"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="reform_box">
                      <div className="reform_content">
                        <span>01</span>
                        <h3>Android Smartphones</h3>
                        <p>
                          85% of the smartphones today are android, ask
                          yourself- is your business there? We offer development
                          services to deploy exceptionally secure, technically
                          advanced, and feature-packed apps for Android
                          Smartphones.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="reform_box">
                      <div className="reform_img">
                        <img
                          src="assets/imgs/service/ios.png"
                          alt="Android Smartphones"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="reform_box">
                      <div className="reform_content">
                        <span>01</span>
                        <h3>Android Smartphones</h3>
                        <p>
                          85% of the smartphones today are android, ask
                          yourself- is your business there? We offer development
                          services to deploy exceptionally secure, technically
                          advanced, and feature-packed apps for Android
                          Smartphones.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="hire__area">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="hire__content">
                <h2
                  className="hire__title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  Do You Have a Project In Your Mind ?
                </h2>
                <Link
                  to="/contact-us"
                  className="cxu-btn-border wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  HIRE US NOW
                </Link>
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/imgs/hero/overlay.png"
          alt="overlay"
          className="overlay"
        />
      </section>

      <Footer />
    </>
  );
}

export default AndroidServices;
