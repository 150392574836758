import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Banner() {
  return (
    <>
      <section className="hero__area-2">
        <div className="container">
          <img
            src="assets/imgs/bg/hero-shape-2.png"
            alt="Image"
            class="hero-shape-one"
          />
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6  col-sm-12 col-12">
              <div className="hero__content">
                <h1 className="hero__sub-title cxufadeUp">Business Solution</h1>
                <h2 className="hero__title cxufadeUp2">
                  We Are Digital Agency
                </h2>
                <p>
                  There are many variations of passages of Lorem Ipsum
                  available, the majority have suffered alteration in some form
                  by injected humour.
                </p>
                <Link to="/about-us" className="cxu-btn-border">
                  DISCOVER MORE
                </Link>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6  col-sm-12 col-12 mt-xxl-0 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-5 mt-5">
              <img
                src="assets/imgs/bg/banner1.png"
                alt="Image"
                className="hero_area-2_img"
              />
              <img
                src="assets/imgs/bg/about7_chart.png"
                alt=""
                class="rock7 moveVertical"
              />
            </div>
          </div>
        </div>
        <img
          className="footer-shape"
          src="assets/imgs/shapee/image.png"
          alt="image"
        />
      </section>

      <section className="feature__area-2">
        <div className="container">
          <div className="feature__inner-2">
            <div className="row">
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                <div
                  className="feature__item-2 wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.15s"
                >
                  <img
                    src="assets/imgs/feature/8.png"
                    alt="Icon"
                    className="feature__icon"
                  />
                  <h2 className="feature__text-2">
                    Stock Free <br />
                    Amazing Resources
                  </h2>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                <div
                  className="feature__item-2 wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.3s"
                >
                  <img
                    src="assets/imgs/feature/5.png"
                    alt="Icon"
                    className="feature__icon"
                  />
                  <h2 className="feature__text-2">
                    Amazing <br />
                    Exclusive Designs
                  </h2>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                <div
                  className="feature__item-2 wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.45s"
                >
                  <img
                    src="assets/imgs/feature/6.png"
                    alt="Icon"
                    className="feature__icon"
                  />
                  <h2 className="feature__text-2">
                    Proper <br />
                    Documentations
                  </h2>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                <div
                  className="feature__item-2 wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.6s"
                >
                  <img
                    src="assets/imgs/feature/7.png"
                    alt="Icon"
                    className="feature__icon"
                  />
                  <h2 className="feature__text-2">
                    Creative <br />
                    Services Outlines
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Banner;
