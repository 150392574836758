import React from "react";
import { Link } from "react-router-dom";
import AboutUs from "./commonComponents/aboutUs";
import Banner from "./commonComponents/banner";
import Footer from "./commonComponents/footer";
import Header from "./commonComponents/header";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { LikeButton, Provider } from "@lyket/react";

function Home() {
  return (
    <>
      <Header />
      <Banner />
      <AboutUs />

      <div
        className="sotcox-workflow-section theme-light-bg pb-120 pt-120"
        style={{ backgroundColor: "#e6eefb" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10">
              <div className="sec-title-wrap text-center ">
                <h2
                  className="sec-sub-title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  POPULOR SERVICES{" "}
                </h2>
                <h3
                  className="sec-title-2 wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  We help Brands with
                </h3>
              </div>
            </div>
          </div>
          <div className="row g-0 mt-none-40">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mt-40">
              <Link to="/android-app-development">
                <div
                  className="sotcox-workflow-box sotcox-workflow-box__1 text-center wow fadeInUp active mx-4"
                  data-wow-delay="0ms"
                  data-wow-duration="800ms"
                >
                  <div className="icon">
                    <img src="assets/imgs/icons/service-icon-1.png" alt="" />
                    <span className="count">01</span>
                  </div>
                  <div className="content process-card ">
                    <h5 className="title">
                      {" "}
                      Android
                      <br /> App Development
                    </h5>
                    <p>
                      The first way to beat a creative block and generate new
                      ideas is to generate as many{" "}
                    </p>
                  </div>
                  <div className="sotcox-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mt-40 wow fadeInUp mt-xxl-0 mt-xl-0 mt-lg-0 mt-md-0 mt-5 "
              data-wow-delay="200ms"
              data-wow-duration="800ms"
            >
              <Link to="/ios-app-development">
                <div
                  className="sotcox-workflow-box sotcox-workflow-box__1  text-center active mx-4"
                  style={{ background: "#fff" }}
                >
                  <div className="icon">
                    <img src="assets/imgs/icons/service-icon-2.png" alt="" />
                    <span className="count">02</span>
                  </div>
                  <div className="content process-card">
                    <h5 className="title">
                      {" "}
                      iOS
                      <br /> App Development
                    </h5>
                    <p>
                      The first way to beat a creative block and generate new
                      ideas is to generate as many{" "}
                    </p>
                  </div>
                  <div className="sotcox-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mt-40 wow fadeInUp  mt-xxl-0 mt-xl-0 mt-lg-0 mt-md-5 mt-5"
              data-wow-delay="400ms"
              data-wow-duration="800ms"
            >
              <Link to="/react-app-development">
                <div className="sotcox-workflow-box sotcox-workflow-box__1 text-center active mx-4">
                  <div className="icon">
                    <img src="assets/imgs/icons/service-icon-3.png" alt="" />
                    <span className="count">03</span>
                  </div>
                  <div className="content process-card">
                    <h5 className="title">
                      {" "}
                      React
                      <br /> Development
                    </h5>
                    <p>
                      The first way to beat a creative block and generate new
                      ideas is to generate as many{" "}
                    </p>
                  </div>
                  <div className="sotcox-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mt-5">
              <Link to="/website-app-development">
                <div
                  className="sotcox-workflow-box sotcox-workflow-box__1 text-center wow fadeInUp active mx-4"
                  data-wow-delay="0ms"
                  data-wow-duration="800ms"
                >
                  <div className="icon">
                    <img src="assets/imgs/icons/service-icon-1.png" alt="" />
                    <span className="count">04</span>
                  </div>
                  <div className="content process-card ">
                    <h5 className="title">
                      {" "}
                      Website <br /> Development
                    </h5>
                    <p>
                      The first way to beat a creative block and generate new
                      ideas is to generate as many{" "}
                    </p>
                  </div>
                  <div className="sotcox-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mt-5 wow fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="800ms"
            >
              <Link to="/data-science-development">
                <div
                  className="sotcox-workflow-box sotcox-workflow-box__1  text-center active mx-4"
                  style={{ background: "#fff" }}
                >
                  <div className="icon">
                    <img src="assets/imgs/icons/service-icon-2.png" alt="" />
                    <span className="count">05</span>
                  </div>
                  <div className="content process-card">
                    <h5 className="title"> Data Science & Data Analysis</h5>
                    <p>
                      The first way to beat a creative block and generate new
                      ideas is to generate as many{" "}
                    </p>
                  </div>
                  <div className="sotcox-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </Link>
            </div>

            <div
              className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mt-5 wow fadeInUp "
              data-wow-delay="400ms"
              data-wow-duration="800ms"
            >
              <Link to="/machine-learning-development">
                <div className="sotcox-workflow-box sotcox-workflow-box__1 text-center active mx-4">
                  <div className="icon">
                    <img src="assets/imgs/icons/service-icon-3.png" alt="" />
                    <span className="count">06</span>
                  </div>
                  <div className="content process-card">
                    <h5 className="title">
                      {" "}
                      Artificial Intelligence & Machine Learning
                    </h5>
                    <p>
                      The first way to beat a creative block and generate new
                      ideas is to generate as many{" "}
                    </p>
                  </div>
                  <div className="sotcox-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <img
          className="footer-shape"
          src="assets/imgs/shapee/image.png"
          alt="image"
        />
      </div>

      <section className="process__area">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="process__content">
                <h2
                  className="process__title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  Our Working Process With Team Leaders
                </h2>
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#workProcessModal"
                  className="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <img src="assets/imgs/work-process/1.png" alt="Icon" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/imgs/hero/overlay.png"
          alt="overlay"
          className="overlay"
        />
      </section>

      <div
        className="modal fade modal__wkprocess"
        id="workProcessModal"
        tabindex="-1"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <button className="modal-close" data-bs-dismiss="modal">
              <i className="fa-solid fa-xmark"></i>
            </button>
            <div className="modal-body">
              <iframe
                src="https://www.youtube.com/embed/CH50zuS8DD0"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <div className="sotcox-project-section pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-xl-4">
              <div className="content-left-wrapper">
                <div className="sotcox-section-heading ">
                  <h2
                    className="sec-sub-title wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.2s"
                  >
                    RECENT PROJECTS
                  </h2>
                  <h3
                    className="sec-title wow animate__animated animate__fadeInUp "
                    data-wow-delay="0.4s"
                  >
                    Our latest case studies
                  </h3>

                  <p className="mb-4">
                    Why I say old chap that is spiffing tickety-boo amongst,
                    brolly bugger say William in my flat Elizabeth tosser.
                    Bugger all mate bite your arm off chancer arse over tit
                    gormless only a quid it's all gone to pot crikey bloke on
                    your bike mate hunky-dory tit gormless only a quid it's all
                    gone to pot crikey bloke on your bike mate hunky-dory.
                  </p>
                </div>
                {/* <div className="filters">
                  <ul className="sotcox-project-filter list-unstyled filter-menu d-flex align-items-center mt-35">
                    <Link to="/portfolio">
                      {" "}
                      <li className="is-checked">Blockchain & Metavarse</li>
                    </Link>
                    <Link to="/portfolio">
                      {" "}
                      <li>Android App</li>
                    </Link>
                    <Link to="/portfolio">
                      {" "}
                      <li>Ui/Ux Design</li>
                    </Link>
                    <Link to="/portfolio">
                      {" "}
                      <li>Machine Learning</li>
                    </Link>
                    <Link to="/portfolio">
                      {" "}
                      <li>Website Development</li>
                    </Link>
                  </ul>
                </div> */}

                <Link to="/portfolio" className="cxu-btn-border-2  mx-auto">
                  VIEW ALL SAMPLES <span></span>
                </Link>
              </div>
            </div>
            <div className="col-xl-8">
              <div className="sotcox-project-wrapper">
                <div className="row sotcox-project-grid mt-none-30 ">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 cat-1 cat-4 cat-3 mt-30 grid">
                    <div className="sotcox-project-item ">
                      <div
                        className="portfolio__item wow animate__animated animate__fadeInUp"
                        data-wow-delay="0.2s"
                      >
                        <Link to="/portfolio">
                          <img
                            src="assets/imgs/portfolio/5.png"
                            alt="Portfolio Thumb"
                            className="pf-thumb"
                          />
                          <div className="portfolio__info">
                            <h4 className="portfolio__title">
                              Mobile UI Designs
                            </h4>
                            <h5 className="portfolio__cat">
                              Social Media Marketing
                            </h5>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 cat-2 cat-4 cat-3 mt-30 grid mt-xxl-0 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-0 mt-3">
                    <div className="sotcox-project-item">
                      <div
                        className="portfolio__item wow animate__animated animate__fadeInUp"
                        data-wow-delay="0.4s"
                      >
                        <Link to="/portfolio">
                          <img
                            src="assets/imgs/portfolio/6.png"
                            alt="Portfolio Thumb"
                            className="pf-thumb"
                          />
                          <div className="portfolio__info">
                            <h4 className="portfolio__title">
                              Product Screen Design
                            </h4>
                            <h5 className="portfolio__cat">
                              Social Media Marketing
                            </h5>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 cat-4 cat-2 cat-3 mt-3 grid">
                    <div className="sotcox-project-item">
                      <div
                        className="portfolio__item wow animate__animated animate__fadeInUp"
                        data-wow-delay="0.6s"
                      >
                        <Link to="/portfolio">
                          <img
                            src="assets/imgs/portfolio/1.png"
                            alt="Portfolio Thumb"
                            className="pf-thumb"
                          />
                          <div className="portfolio__info">
                            <h4 className="portfolio__title">
                              WordPress Website
                            </h4>
                            <h5 className="portfolio__cat">
                              Social Media Marketing
                            </h5>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 cat-4 cat-2 cat-3 mt-3 grid">
                    <div className="sotcox-project-item">
                      <div
                        className="portfolio__item wow animate__animated animate__fadeInUp"
                        data-wow-delay="0.6s"
                      >
                        <Link to="/portfolio">
                          <img
                            src="assets/imgs/portfolio/1.png"
                            alt="Portfolio Thumb"
                            className="pf-thumb"
                          />
                          <div className="portfolio__info">
                            <h4 className="portfolio__title">
                              WordPress Website
                            </h4>
                            <h5 className="portfolio__cat">
                              Social Media Marketing
                            </h5>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        className="footer-shape-2"
        src="assets/imgs/shapee/footer-shape.png"
        alt="image"
      />

      <section
        className="about__area pt-120 pb-120"
        style={{ backgroundColor: "#e6eefb" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 d-flex justify-content-center align-items-center">
              <div className="">
                <img
                  src="assets/imgs/bg/wcu-bg-1.png"
                  alt="Image"
                  className="image moveVertical"
                />
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
              <div className="about__right   ">
                <div className="about__right-inner  ">
                  <h2
                    className="sec-sub-title wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.2s"
                  >
                    WHY CHOOSE US
                  </h2>
                  <h3
                    className="sec-title wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    Why choose our own IT service?
                  </h3>
                  <p
                    className="wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    There are many variations of passages of Lorem Ipsum
                    available, the majority have suffered alteration in some
                    form, by injected humour. randomised words which don't look
                    even slightly believable. It uses a dictionary of over 200
                    Latin words, combined with the majority have suffered
                    alteration in some form, by injected humour. randomised
                    words which don't look even slightly believable.
                  </p>
                  <ul className="featured-lists list-unstyled mt-4">
                    <li>
                      {" "}
                      <i className="fas fa-star"></i> Automatically syncs across
                      all your devices.
                    </li>
                    <li>
                      <i className="fas fa-star"></i> Create And Save Your Notes
                      With- Media
                    </li>
                  </ul>

                  <Link
                    to="/about-us"
                    className="cxu-btn-border-2 wow animate__animated animate__fadeInUp mt-4"
                    data-wow-delay="0.4s"
                  >
                    READ MORE
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          className="footer-shape"
          src="assets/imgs/shapee/image.png"
          alt="image"
        />
      </section>

      <section className="testimonial__area pt-125 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="sec-title-wrap">
                <h2
                  className="sec-sub-title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  Testimonials
                </h2>
                <h3
                  className="sec-title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  Customer's Awesome <br />
                  <span>Feedback.</span>
                </h3>
              </div>
              <div className="testimonial__slider-wrap">
                <div className="swiper testimonial__slider">
                  <div className="swiper-wrapper">
                    <Swiper
                      spaceBetween={30}
                      centeredSlides={true}
                      autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                      }}
                      pagination={{
                        clickable: true,
                      }}
                      navigation={true}
                      modules={[Autoplay, Pagination, Navigation]}
                      className="mySwiper"
                    >
                      <SwiperSlide>
                        <div className="testimonial__slide">
                          <img
                            src="assets/imgs/testimonial/1.jpg"
                            alt="Client"
                            className="wow animate__animated animate__fadeInUp"
                            data-wow-delay="0.3s"
                          />
                          <p
                            className="wow animate__animated animate__fadeInUp"
                            data-wow-delay="0.5s"
                          >
                            Donec scelerisque dolor id nunc dictum, interdum
                            gravida mauris rhoncus. Aliquam at ultrices nunc. In
                            sem leo, fermentum at lorem in, porta finibus
                            mauris. Aliquam consectetur, ex in gravida
                            porttitor. There are many variations of passages
                          </p>
                          <h4
                            className="testimonial__name wow animate__animated animate__fadeInUp"
                            data-wow-delay="0.7s"
                          >
                            Nirob Aronno
                          </h4>
                          <h5
                            className="testimonial__role wow animate__animated animate__fadeInUp mb-5"
                            data-wow-delay="0.9s"
                          >
                            Marketing Manager
                          </h5>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="testimonial__slide">
                          <img
                            src="assets/imgs/testimonial/2.jpg"
                            alt="Client"
                            className="wow animate__animated animate__fadeInUp"
                            data-wow-delay="0.3s"
                          />
                          <p
                            className="wow animate__animated animate__fadeInUp"
                            data-wow-delay="0.5s"
                          >
                            Donec scelerisque dolor id nunc dictum, interdum
                            gravida mauris rhoncus. Aliquam at ultrices nunc. In
                            sem leo, fermentum at lorem in, porta finibus
                            mauris. Aliquam consectetur, ex in gravida
                            porttitor. There are many variations of passages
                          </p>
                          <h4
                            className="testimonial__name wow animate__animated animate__fadeInUp"
                            data-wow-delay="0.7s"
                          >
                            Jonathon Fedrik
                          </h4>
                          <h5
                            className="testimonial__role wow animate__animated animate__fadeInUp mb-5"
                            data-wow-delay="0.9s"
                          >
                            Marketing Manager
                          </h5>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="testimonial__slide">
                          <img
                            src="assets/imgs/testimonial/3.jpg"
                            alt="Client"
                            className="wow animate__animated animate__fadeInUp"
                            data-wow-delay="0.3s"
                          />
                          <p
                            className="wow animate__animated animate__fadeInUp"
                            data-wow-delay="0.5s"
                          >
                            Donec scelerisque dolor id nunc dictum, interdum
                            gravida mauris rhoncus. Aliquam at ultrices nunc. In
                            sem leo, fermentum at lorem in, porta finibus
                            mauris. Aliquam consectetur, ex in gravida
                            porttitor. There are many variations of passages
                          </p>
                          <h4
                            className="testimonial__name wow animate__animated animate__fadeInUp"
                            data-wow-delay="0.7s"
                          >
                            Samrat Devnath
                          </h4>
                          <h5
                            className="testimonial__role wow animate__animated animate__fadeInUp mb-5"
                            data-wow-delay="0.9s"
                          >
                            Marketing Manager
                          </h5>
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>

                  <div
                    className="swiper-pagination wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.8s"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          className="footer-shape-2"
          src="assets/imgs/shapee/footer-shape.png"
          alt="image"
        />
      </section>

      <section className="counter__area-2 pt-130 pb-130">
        <div className="container">
          <div className="counter__inner-2">
            <div className="row">
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                <div
                  className="counter__item-2 wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.15s"
                >
                  <div className="counter-active counter__number-2">30K+</div>
                  <h3 className="counter__text-2">HAPPY CUSTOMERS</h3>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                <div
                  className="counter__item-2 wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.3s"
                >
                  <div className="counter-active counter__number-2">90+</div>
                  <h3 className="counter__text-2">SKILLED EMPLOYEES</h3>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                <div
                  className="counter__item-2 wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.45s"
                >
                  <div className="counter-active counter__number-2">27K+</div>
                  <h3 className="counter__text-2">PROJECT COMPLETED</h3>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                <div
                  className="counter__item-2 wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.6s"
                >
                  <div className="counter-active counter__number-2">120+</div>
                  <h3 className="counter__text-2">ACHIEVE DTROPHY AWARDS</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/imgs/hero/overlay-2.png"
          alt="overlay"
          className="overlay"
        />
        <img src="assets/imgs/shape/1.png" alt="shape" className="shape-1" />
        <img src="assets/imgs/shape/2.png" alt="shape" className="shape-2" />
        <img src="assets/imgs/shape/3.png" alt="shape" className="shape-3" />
        <img src="assets/imgs/shape/3.png" alt="shape" className="shape-4" />
      </section>
      <section className="video__area pt-100 pb-100">
        <div className="container">
          <div className="video__top">
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <div className="video__left">
                  <h2
                    className="sec-sub-title wow animate__animated animate__fadeInUp text-center"
                    data-wow-delay="0.2s"
                  >
                    Our Strategy
                  </h2>
                  <h3
                    className="sec-title wow animate__animated animate__fadeInUp text-center"
                    data-wow-delay="0.4s"
                  >
                    Our Agile Development Strategy
                  </h3>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, the majority have suffered alteration in some
                    form, by injected humour. randomised words which don't look
                    even slightly believable. It uses a dictionary of over 200
                    Latin words, combined with the majority have suffered
                    alteration in some form, by injected humour. randomised
                    words which don't look even slightly believable.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-12 d-flex justify-content-center my-3">
            <div
              className="video__wrap wow animate__animated animate__zoomIn"
              data-wow-delay="0.2s"
            >
              <img
                src="assets/imgs/service/powering-solution.png"
                alt="Service full image"
                className="w-75 mx-auto d-block moveVertical"
              />
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <Link
              to="/about-us"
              className="cxu-btn-border wow animate__animated animate__fadeInUp mt-5"
              data-wow-delay="0.4s"
              style={{ zIndex: "9" }}
            >
              Read More
            </Link>
          </div>
        </div>

        <div className="integration">
          <img
            src="assets/imgs/about/intg_back.png"
            alt=""
            className="intg-back"
          />
        </div>
      </section>
      <section className="blog__area pt-100 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 text-center">
              <div className="">
                <h2
                  className="sec-sub-title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  Our Blog
                </h2>
                <h3
                  className="sec-title-2 wow animate__animated animate__fadeInUp "
                  data-wow-delay="0.4s"
                >
                  Our Recent Posts
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
              <div
                className="blog__item wow animate__animated animate__fadeInUp"
                data-wow-delay="0.2s"
              >
                <Link to="/blog-details">
                  <img src="assets/imgs/blog/1.png" alt="Blog Image" />
                </Link>
                <div className="blog__content">
                  <div className="date mb-0">22 JAN 2020</div>
                  <p className="postedBy">
                    <Link to="">
                      <i className="fa-regular fa-user"></i> Nirob Aronno
                    </Link>
                  </p>
                  <Link to="/blog-details">
                    <h2 className="blog__title">
                      How to Increase Business Through SEM
                    </h2>
                  </Link>
                  <p>
                    We are passionate about our works. Our designers ahead of
                    the curve.
                  </p>
                  <div className="d-flex justify-content-between align-items-center">
                    <Link to="/blog-details" className="link">
                      Read More
                    </Link>
                    <Link className="like " to="">
                      <Provider
                        apiKey="acc0dbccce8e557db5ebbe6d605aaa"
                        theme={{
                          colors: {
                            text: "#1b2e59",
                            primary: "#22c1c3",
                            highlight: "#00aded",
                            icon: "#00aded",
                          },
                        }}
                      >
                        <LikeButton
                          namespace="my-blog-post"
                          id="how-to-beat-me-at-chess-1"
                        />
                      </Provider>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
              <div
                className="blog__item wow animate__animated animate__fadeInUp"
                data-wow-delay="0.4s"
              >
                <Link to="/blog-details">
                  <img src="assets/imgs/blog/2.png" alt="Blog Image" />
                </Link>
                <div className="blog__content">
                  <div className="date mb-0">22 JAN 2020</div>
                  <p className="postedBy">
                    <Link to="">
                      <i className="fa-regular fa-user"></i> Nirob Aronno
                    </Link>
                  </p>
                  <Link to="/blog-details">
                    <h2 className="blog__title">
                      How to Increase Business Through SEM
                    </h2>
                  </Link>
                  <p>
                    We are passionate about our works. Our designers ahead of
                    the curve.
                  </p>
                  <div className="d-flex justify-content-between align-items-center">
                    <Link to="/blog-details" className="link">
                      Read More
                    </Link>
                    <Link className="like " to="">
                      <Provider
                        apiKey="acc0dbccce8e557db5ebbe6d605aaa"
                        theme={{
                          colors: {
                            text: "#1b2e59",
                            primary: "#22c1c3",
                            highlight: "#00aded",
                            icon: "#00aded",
                          },
                        }}
                      >
                        <LikeButton
                          namespace="my-blog-post"
                          id="how-to-beat-me-at-chess-2"
                        />
                      </Provider>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
              <div
                className="blog__item wow animate__animated animate__fadeInUp"
                data-wow-delay="0.6s"
              >
                <Link to="/blog-details">
                  <img src="assets/imgs/blog/3.png" alt="Blog Image" />
                </Link>
                <div className="blog__content">
                  <div className="date mb-0">22 JAN 2020</div>
                  <p className="postedBy">
                    <Link to="">
                      <i className="fa-regular fa-user"></i> Nirob Aronno
                    </Link>
                  </p>
                  <Link to="/blog-details">
                    <h2 className="blog__title">
                      How to Increase Business Through SEM
                    </h2>
                  </Link>
                  <p>
                    We are passionate about our works. Our designers ahead of
                    the curve.
                  </p>
                  <div className="d-flex justify-content-between align-items-center">
                    <Link to="/blog-details" className="link">
                      Read More
                    </Link>
                    <Link className="like " to="">
                      <Provider
                        apiKey="acc0dbccce8e557db5ebbe6d605aaa"
                        theme={{
                          colors: {
                            text: "#1b2e59",
                            primary: "#22c1c3",
                            highlight: "#00aded",
                            icon: "#00aded",
                          },
                        }}
                      >
                        <LikeButton
                          namespace="my-blog-post"
                          id="how-to-beat-me-at-chess-3"
                        />
                      </Provider>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          className="footer-shape"
          src="assets/imgs/shapee/footer-shape.png"
          alt="image"
        />
      </section>
      <Footer />
    </>
  );
}

export default Home;
