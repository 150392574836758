import React from "react";
import { Link } from "react-router-dom";
import AboutUs from "./commonComponents/aboutUs";
import Footer from "./commonComponents/footer";
import Header from "./commonComponents/header";

function AboutUsDetail() {
  return (
    <>
      <Header />
      <section className="breadcrumb__area">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__content">
                <h1
                  className="breadcrumb__title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  About Us
                </h1>
                <p
                  className="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <Link to="/">Home</Link>
                  <span>About Us</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/imgs/bg/cyber_banner_image.svg"
          alt="overlay"
          className="overlay"
        />
      </section>
      <section className="feature__area pt-100 pb-110 ">
        <div className="container">
          <div className="row">
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
              <div
                className="feature__item wow animate__animated animate__fadeInUp"
                data-wow-delay="0.2s"
              >
                <img
                  src="assets/imgs/feature/1.png"
                  alt="Icon"
                  className="feature__icon"
                />
                <p className="feature__text">
                  Stock Free <br />
                  Amazing Resources
                </p>
              </div>
            </div>

            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
              <div
                className="feature__item wow animate__animated animate__fadeInUp"
                data-wow-delay="0.4s"
              >
                <img
                  src="assets/imgs/feature/9.png"
                  alt="Icon"
                  className="feature__icon"
                />
                <p className="feature__text">
                  Amazing <br />
                  Exclusive Designs
                </p>
              </div>
            </div>

            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
              <div
                className="feature__item wow animate__animated animate__fadeInUp"
                data-wow-delay="0.6s"
              >
                <img
                  src="assets/imgs/feature/3.png"
                  alt="Icon"
                  className="feature__icon"
                />
                <p className="feature__text">
                  Proper <br />
                  Documentations
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
              <div
                className="feature__item wow animate__animated animate__fadeInUp"
                data-wow-delay="0.8s"
              >
                <img
                  src="assets/imgs/feature/4.png"
                  alt="Icon"
                  className="feature__icon"
                />
                <p className="feature__text">
                  Creative <br />
                  Services Outlines
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about__area-2 pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
              <div className="about__right-2 pt-xxl-5 pt-xl-5 pt-lg-0 pt-md-0">
                <div className="about__right-inner-2 pt-xxl-5 pt-xl-5 pt-lg-0 pt-md-0">
                  <h2
                    className="sec-sub-title wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.3s"
                  >
                    Why Choose Us
                  </h2>
                  <h3
                    className="sec-title wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    GREAT Solutions For
                    <br /> Your Business
                  </h3>
                  <p
                    className="wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    There are many variations of passages of Lorem Ipsum
                    available, the majority have suffered alteration in some
                    form, by injected humour. randomised words which don't look
                    even slightly believable. It uses a dictionary of over 200
                    Latin words, combined with a handful of models.
                  </p>
                  <ul
                    className="list wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.6s"
                  >
                    <li>
                      <div className="icon">
                        <img src="assets/imgs/feature/1.png" alt="" />
                      </div>
                      <span>
                        Creative <br />
                        Concept Execution
                      </span>
                    </li>
                    <li>
                      <div className="icon">
                        <img src="assets/imgs/feature/4.png" alt="" />
                      </div>
                      <span>
                        One Stop <br />
                        Business Solution
                      </span>
                    </li>
                  </ul>
                  <Link
                    to="/services"
                    className="cxu-btn-border-2 wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.6s"
                  >
                    READ MORE
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
              <div className="about__left-2">
                <h2
                  className="exp wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="assets/imgs/about/company-about.png"
                    alt=""
                    className="moveHorizontal"
                  />
                </h2>
              </div>
            </div>
          </div>
        </div>
        <img
          className="footer-shape"
          src="assets/imgs/shapee/footer-shape.png"
          alt="image"
        />
      </section>
      <section className="counter__area-2 pt-130 pb-130">
        <div className="container">
          <div className="counter__inner-2">
            <div className="row">
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                <div
                  className="counter__item-2 wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.15s"
                >
                  <div className="counter-active counter__number-2">30K+</div>
                  <h3 className="counter__text-2">HAPPY CUSTOMERS</h3>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                <div
                  className="counter__item-2 wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.3s"
                >
                  <div className="counter-active counter__number-2">90+</div>
                  <h3 className="counter__text-2">SKILLED EMPLOYEES</h3>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                <div
                  className="counter__item-2 wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.45s"
                >
                  <div className="counter-active counter__number-2">27K+</div>
                  <h3 className="counter__text-2">PROJECT COMPLETED</h3>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                <div
                  className="counter__item-2 wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.6s"
                >
                  <div className="counter-active counter__number-2">120+</div>
                  <h3 className="counter__text-2">ACHIEVE DTROPHY AWARDS</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/imgs/hero/overlay-2.png"
          alt="overlay"
          className="overlay"
        />
        <img src="assets/imgs/shape/1.png" alt="shape" className="shape-1" />
        <img src="assets/imgs/shape/2.png" alt="shape" className="shape-2" />
        <img src="assets/imgs/shape/3.png" alt="shape" className="shape-3" />
        <img src="assets/imgs/shape/3.png" alt="shape" className="shape-4" />
      </section>

      <section className=" pt-120 ">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 text-center">
              <div className="sec-title-wrap">
                <h2
                  className="sec-sub-title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  OUR PHILOSOPHY
                </h2>
                <h3
                  className="sec-title-2 wow animate__animated animate__fadeInUp "
                  data-wow-delay="0.4s"
                >
                  Our Business Philosophy
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6  mb-xxl-0 mb-xl-0 mb-lg-0 mb-md-5 mb-sm-0 mb-0">
              <div className="about__right-2 pt-xxl-5 pt-xl-5 pt-lg-0 pt-md-0">
                <div className="about__right-inner-2 pt-xxl-5 pt-xl-5 pt-lg-0 pt-md-0">
                  <h2
                    className="sec-sub-title wow animate__animated animate__fadeInUp mt-xxl-0 mt-xl-0 mt-lg-5 mt-md-5 mt-sm-0 mt-0
                   "
                    data-wow-delay="0.3s"
                  >
                    SUPERVISION
                  </h2>
                  <h3
                    className="sec-title wow animate__animated animate__fadeInUp "
                    data-wow-delay="0.4s"
                  >
                    Leadership
                  </h3>
                  <p
                    className="wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. Lorem Ipsum is simply dummy text of the
                    printing and typesetting industry.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
              <div className="about__left-2">
                <h2
                  className="exp wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  <img
                    src="assets/imgs/about/leadership.jpg"
                    alt=""
                    classNameName=""
                  />
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
              <div className="about__left-2">
                <h2
                  className="exp wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  <img src="assets/imgs/about/collaboration.jpg" alt="" />
                </h2>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6  mb-xxl-0 mb-xl-0 mb-lg-0 mb-md-5 mb-sm-0 mb-0">
              <div className="about__right-2 pt-xxl-5 pt-xl-5 pt-lg-0 pt-md-0">
                <div className="about__right-inner-2 pt-xxl-5 pt-xl-5 pt-lg-0 pt-md-0">
                  <h2
                    className="sec-sub-title wow animate__animated animate__fadeInUp mt-xxl-0 mt-xl-0 mt-lg-5 mt-md-5 mt-sm-0 mt-0
                   "
                    data-wow-delay="0.3s"
                  >
                    STRENGTH
                  </h2>
                  <h3
                    className="sec-title wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    Team Collaboration
                  </h3>
                  <p
                    className="wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. Lorem Ipsum is simply dummy text of the
                    printing and typesetting industry.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 ">
              <div className="about__right-2 pt-xxl-5 pt-xl-5 pt-lg-0 pt-md-0">
                <div className="about__right-inner-2 pt-xxl-5 pt-xl-5 pt-lg-0 pt-md-0">
                  <h2
                    className="sec-sub-title wow animate__animated animate__fadeInUp mt-xxl-0 mt-xl-0 mt-lg-5 mt-md-5 mt-sm-5 mt-5
                   "
                    data-wow-delay="0.3s"
                  >
                    QUALITY
                  </h2>
                  <h3
                    className="sec-title wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    Transparency & Integrity
                  </h3>
                  <p
                    className="wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. Lorem Ipsum is simply dummy text of the
                    printing and typesetting industry.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
              <div className="about__left-2">
                <h2
                  className="exp wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  <img src="assets/imgs/about/transparency.jpg" alt="" />
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default AboutUsDetail;
