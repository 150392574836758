import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";

function Footer() {
  const form = useRef();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange", //"onBlur"
  });

  const sendEmail = (data) => {
    emailjs
      .sendForm(
        "service_f84jkzi",
        "template_kc6rwae",
        form.current,
        "B6cpfk6eucerf0fKl"
      )
      .then(
        (result) => {
          console.log(result.text);
          Swal.fire({
            icon: "success",
            title: "Thank you for getting in touch!",
            text: "We appreciate you contacting us. One of our colleagues will get back in touch with you soon!Have a great day!",

            width: 500,

            showCloseButton: true,
            showConfirmButton: false,
          });
          reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <>
      <div className="brand__area">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="brand__list">
                <div className="slider">
                  <div className="slide-track">
                    <div className="slide px-4">
                      <img src="assets/imgs/about/badges-a.png" alt="" />
                    </div>
                    <div className="slide px-4">
                      <img src="assets/imgs/about/badges-b.png" alt="" />
                    </div>
                    <div className="slide px-4">
                      <img src="assets/imgs/about/badges-c.png" alt="" />
                    </div>
                    <div className="slide px-4">
                      <img src="assets/imgs/about/badges-d.png" alt="" />
                    </div>
                    <div className="slide px-4">
                      <img src="assets/imgs/about/badges-a.png" alt="" />
                    </div>
                    <div className="slide px-4">
                      <img src="assets/imgs/about/badges-b.png" alt="" />
                    </div>
                    <div className="slide px-4">
                      <img src="assets/imgs/about/badges-c.png" alt="" />
                    </div>
                    <div className="slide px-4">
                      <img src="assets/imgs/about/badges-d.png" alt="" />
                    </div>
                    <div className="slide px-4">
                      <img src="assets/imgs/about/badges-a.png" alt="" />
                    </div>
                    <div className="slide">
                      <img src="assets/imgs/about/badges-b.png" alt="" />
                    </div>
                    <div className="slide">
                      <img src="assets/imgs/about/badges-c.png" alt="" />
                    </div>
                    <div className="slide">
                      <img src="assets/imgs/about/badges-d.png" alt="" />
                    </div>
                    <div className="slide">
                      <img src="assets/imgs/about/badges-a.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="newsletter__area">
        <div className="container">
          <div className="newsletter__inner">
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                <div
                  className="newsletter__content wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  <h2 className="title">NEWSLETTER</h2>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered.
                  </p>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                <div className="newsletter__form">
                  <div
                    className="newsletter wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    <form ref={form} onSubmit={handleSubmit(sendEmail)}>
                      <input
                        type="text"
                        placeholder="Enter your email"
                        name="email"
                        id="email"
                        {...register("email", {
                          required: "This field is required",
                        })}
                      />

                      <button type="submit" className="submit">
                        SUBSCRIBE
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className="footer__area-2">
        <div className="footer__top">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="footer__widgets">
                  <div className="widget__one">
                    <img
                      src="assets/imgs/logo/logo.png"
                      alt="Footer Logo"
                      className="footer__logo"
                    />
                    <p>
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form, by injected humour
                    </p>

                    <ul className="social-media">
                      <li>
                        <a
                          href="https://www.facebook.com/appdelivers"
                          target="_blank"
                        >
                          <i className="fa-brands fa-facebook-f"></i>
                        </a>
                      </li>

                      <li>
                        <a href="#">
                          <i className="fa-brands fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-brands fa-linkedin"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="footer__widget-two">
                    <h2 className="footer__widget-title">My Account</h2>
                    <ul className="footer__menu">
                      <li>
                        <Link to="/term-conditions">Terms & Conditions</Link>
                      </li>
                      <li>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/about-us">About Us</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="footer__widget-three">
                    <h2 className="footer__widget-title">Our Company</h2>
                    <ul className="footer__menu">
                      <li>
                        <Link to="/">Home</Link>
                      </li>

                      <li>
                        <Link to="/services">Services</Link>
                      </li>
                      <li>
                        <Link to="/portfolio">Portfolio</Link>
                      </li>
                      <li>
                        <Link to="/blogs">Blogs</Link>
                      </li>
                      <li>
                        <Link to="/contact-us">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="footer__widget-five">
                    <h2 className="footer__widget-title">
                      Contact Information
                    </h2>
                    <ul className="footer__location">
                      <li>
                        <i className="fa-solid fa-location-dot"></i>
                        <span>
                          500 Treat Ave, Suite 200 San Francisco, <br />
                          CA 94110, USA-265
                        </span>
                      </li>
                      <li>
                        <i className="fa-solid fa-phone"></i>
                        <a href="tel:025698259145">+91 9354054810 (Support)</a>
                      </li>
                      <li>
                        <i className="fa-solid fa-envelope"></i>
                        <a href="mailto:gurudev@mail.com">
                          Support@appdelivers.com
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer__btm-2">
          <p>© 2023 All Rights Reserved by App Delivers.</p>
        </div>
      </footer>
      <ScrollToTop
        smooth
        color="#fff"
        style={{
          background: "#007aff",
          borderRadius: "50%",
          height: "45px",
          width: "45px",
          zIndex: "999",
        }}
      />
    </>
  );
}

export default Footer;
