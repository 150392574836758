import React from "react";
import { Link } from "react-router-dom";
import Footer from "./commonComponents/footer";
import Header from "./commonComponents/header";

function ServicesDetail() {
  return (
    <>
      <Header />
      <section className="breadcrumb__area">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__content">
                <h1
                  className="breadcrumb__title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  Services
                </h1>
                <p
                  className="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <Link to="/">Home</Link>
                  <span>Services</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/imgs/bg/cyber_banner_image.svg"
          alt="overlay"
          className="overlay"
        />
      </section>
      <section className="video__area pt-100 pb-100">
        <div className="container">
          <div className="video__top">
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                <div className="video__left">
                  <h2
                    className="sec-sub-title wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.2s"
                  >
                    We Promise For
                  </h2>
                  <h3
                    className="sec-title wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    Quality & Amazing <br />
                    Global Services.
                  </h3>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                <div
                  className="video__right wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.5s"
                >
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, the majority have suffered alteration in some
                    form, by injected humour. randomised words which don't look
                    even slightly believable. It uses a dictionary of over 200
                    Latin words, combined with{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-12">
            <div
              className="video__wrap wow animate__animated animate__zoomIn"
              data-wow-delay="0.2s"
            >
              <img src="assets/imgs/service/1.png" alt="Service full image" />
            </div>
          </div>
        </div>
      </section>
      <div
        className="sotcox-workflow-section theme-light-bg pb-120 pt-120"
        style={{ backgroundColor: "#e6eefb" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10">
              <div className="sec-title-wrap text-center ">
                <h2
                  className="sec-sub-title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  POPULOR SERVICES{" "}
                </h2>
                <h3
                  className="sec-title-2 wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  We help Brands with
                </h3>
              </div>
            </div>
          </div>
          <div className="row g-0 mt-none-40">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mt-40">
              <Link to="/android-app-development">
                <div
                  className="sotcox-workflow-box sotcox-workflow-box__1 text-center wow fadeInUp active mx-4"
                  data-wow-delay="0ms"
                  data-wow-duration="800ms"
                >
                  <div className="icon">
                    <img src="assets/imgs/icons/service-icon-1.png" alt="" />
                    <span className="count">01</span>
                  </div>
                  <div className="content process-card ">
                    <h5 className="title">
                      {" "}
                      Android
                      <br /> App Development
                    </h5>
                    <p>
                      The first way to beat a creative block and generate new
                      ideas is to generate as many{" "}
                    </p>
                  </div>
                  <div className="sotcox-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mt-40 wow fadeInUp mt-xxl-0 mt-xl-0 mt-lg-0 mt-md-0 mt-5 "
              data-wow-delay="200ms"
              data-wow-duration="800ms"
            >
              <Link to="/ios-app-development">
                <div
                  className="sotcox-workflow-box sotcox-workflow-box__1  text-center active mx-4"
                  style={{ background: "#fff" }}
                >
                  <div className="icon">
                    <img src="assets/imgs/icons/service-icon-2.png" alt="" />
                    <span className="count">02</span>
                  </div>
                  <div className="content process-card">
                    <h5 className="title">
                      {" "}
                      iOS
                      <br /> App Development
                    </h5>
                    <p>
                      The first way to beat a creative block and generate new
                      ideas is to generate as many{" "}
                    </p>
                  </div>
                  <div className="sotcox-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mt-40 wow fadeInUp  mt-xxl-0 mt-xl-0 mt-lg-0 mt-md-5 mt-5"
              data-wow-delay="400ms"
              data-wow-duration="800ms"
            >
              <Link to="/react-app-development">
                <div className="sotcox-workflow-box sotcox-workflow-box__1 text-center active mx-4">
                  <div className="icon">
                    <img src="assets/imgs/icons/service-icon-3.png" alt="" />
                    <span className="count">03</span>
                  </div>
                  <div className="content process-card">
                    <h5 className="title">
                      {" "}
                      React
                      <br />
                      Development
                    </h5>
                    <p>
                      The first way to beat a creative block and generate new
                      ideas is to generate as many{" "}
                    </p>
                  </div>
                  <div className="sotcox-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mt-5">
              <Link to="/website-app-development">
                <div
                  className="sotcox-workflow-box sotcox-workflow-box__1 text-center wow fadeInUp active mx-4"
                  data-wow-delay="0ms"
                  data-wow-duration="800ms"
                >
                  <div className="icon">
                    <img src="assets/imgs/icons/service-icon-1.png" alt="" />
                    <span className="count">04</span>
                  </div>
                  <div className="content process-card ">
                    <h5 className="title">
                      {" "}
                      Website
                      <br /> Development
                    </h5>
                    <p>
                      The first way to beat a creative block and generate new
                      ideas is to generate as many{" "}
                    </p>
                  </div>
                  <div className="sotcox-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mt-5 wow fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="800ms"
            >
              <Link to="/data-science-development">
                <div
                  className="sotcox-workflow-box sotcox-workflow-box__1  text-center active mx-4"
                  style={{ background: "#fff" }}
                >
                  <div className="icon">
                    <img src="assets/imgs/icons/service-icon-2.png" alt="" />
                    <span className="count">05</span>
                  </div>
                  <div className="content process-card">
                    <h5 className="title"> Data Science & Data Analysis</h5>
                    <p>
                      The first way to beat a creative block and generate new
                      ideas is to generate as many{" "}
                    </p>
                  </div>
                  <div className="sotcox-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </Link>
            </div>

            <div
              className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mt-5 wow fadeInUp "
              data-wow-delay="400ms"
              data-wow-duration="800ms"
            >
              <Link to="/machine-learning-development">
                <div className="sotcox-workflow-box sotcox-workflow-box__1 text-center active mx-4">
                  <div className="icon">
                    <img src="assets/imgs/icons/service-icon-3.png" alt="" />
                    <span className="count">06</span>
                  </div>
                  <div className="content process-card">
                    <h5 className="title">
                      {" "}
                      Artificial Intelligence & Machine Learning
                    </h5>
                    <p>
                      The first way to beat a creative block and generate new
                      ideas is to generate as many{" "}
                    </p>
                  </div>
                  <div className="sotcox-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mt-5">
              <Link to="/games-development">
                <div
                  className="sotcox-workflow-box sotcox-workflow-box__1 text-center wow fadeInUp active mx-4"
                  data-wow-delay="0ms"
                  data-wow-duration="800ms"
                >
                  <div className="icon">
                    <img src="assets/imgs/icons/service-icon-1.png" alt="" />
                    <span className="count">04</span>
                  </div>
                  <div className="content process-card ">
                    <h5 className="title"> 2D & 3D Games</h5>
                    <p>
                      The first way to beat a creative block and generate new
                      ideas is to generate as many{" "}
                    </p>
                  </div>
                  <div className="sotcox-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </Link>
            </div>
            <div
              className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mt-5 wow fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="800ms"
            >
              <Link to="/blockchain-development">
                <div
                  className="sotcox-workflow-box sotcox-workflow-box__1  text-center active mx-4"
                  style={{ background: "#fff" }}
                >
                  <div className="icon">
                    <img src="assets/imgs/icons/service-icon-2.png" alt="" />
                    <span className="count">05</span>
                  </div>
                  <div className="content process-card">
                    <h5 className="title"> Blockchain, NFT, & Metavarse</h5>
                    <p>
                      The first way to beat a creative block and generate new
                      ideas is to generate as many{" "}
                    </p>
                  </div>
                  <div className="sotcox-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </Link>
            </div>

            <div
              className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mt-5 wow fadeInUp "
              data-wow-delay="400ms"
              data-wow-duration="800ms"
            >
              <Link to="/admin-app-development">
                <div className="sotcox-workflow-box sotcox-workflow-box__1 text-center active mx-4">
                  <div className="icon">
                    <img src="assets/imgs/icons/service-icon-3.png" alt="" />
                    <span className="count">06</span>
                  </div>
                  <div className="content process-card">
                    <h5 className="title"> SAAS, CMS, Admin Panel, CRMS</h5>
                    <p>
                      The first way to beat a creative block and generate new
                      ideas is to generate as many{" "}
                    </p>
                  </div>
                  <div className="sotcox-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <img
          className="footer-shape"
          src="assets/imgs/shapee/image.png"
          alt="image"
        />
      </div>

      <div className="experience-area pt-120 pb-120">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5" data-cues="zoomIn">
              <div className="experience-image d-flex justify-content-center">
                <img
                  className="experience-img"
                  src="assets/imgs/bg/head.png"
                  alt="image"
                />
              </div>
            </div>
            <div className="col-lg-7 ps-xxlg-5 ps-xl-5 ps-lg-0 ps-md-0">
              <div className="business-experience">
                <div className="section-heading">
                  <h2
                    className="sec-sub-title wow animate__animated animate__fadeInUp mt-xxl-0 mt-xl-0 mt-lg-0 mt-md-5 mt-sm-5 mt-5"
                    data-wow-delay="0.3s"
                  >
                    Boost Your Business
                  </h2>
                  <h3
                    className="sec-title wow animate__animated animate__fadeInUp "
                    data-wow-delay="0.4s"
                  >
                    Enjoy Build Your Business Experience
                  </h3>
                  <p
                    className="wow animate__animated animate__fadeInUp mb-4"
                    data-wow-delay="0.5s"
                  >
                    There are many variations of passages of Lorem Ipsum
                    available, the majority have suffered alteration in some
                    form, by injected humour. randomised words which don't look
                    even slightly believable. It uses a dictionary of over 200
                    Latin words, combined with a handful of models.
                  </p>
                </div>
                <div className="row">
                  <div
                    className="col-lg-6 col-md-6 col-xl-6"
                    data-cues="slideInLeft"
                  >
                    <div className="experience-card">
                      <div className="heading align-items-center">
                        <div className="business-img">
                          <img
                            src="assets/imgs/svg/business-1.svg"
                            alt="image"
                          />
                        </div>
                        <h4>Business Solutions</h4>
                      </div>

                      <p>
                        Which is the same as saying through shrinking from toil
                        Ipain
                      </p>
                    </div>
                  </div>

                  <div
                    className="col-lg-6 col-md-6 col-xl-6"
                    data-cues="slideInLeft"
                  >
                    <div className="experience-card">
                      <div className="heading align-items-center">
                        <div className="business-img">
                          <img
                            src="assets/imgs/svg/business-2.svg"
                            alt="image"
                          />
                        </div>
                        <h4>Market Strategy</h4>
                      </div>

                      <p>
                        Which is the same as saying through shrinking from toil
                        Ipain
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="process-area pt-120 pb-120"
        style={{ backgroundColor: "#e6eefb" }}
      >
        <div className="container">
          <div className="section-title">
            <h2
              className="sec-sub-title wow animate__animated animate__fadeInUp text-center"
              data-wow-delay="0.3s"
            >
              Work Process
            </h2>
            <h3
              className="sec-title wow animate__animated animate__fadeInUp text-center "
              data-wow-delay="0.4s"
            >
              Do Your Business The Easy Way
            </h3>
          </div>
          <div className="row justify-content-center mt-5">
            <div className="col-lg-4 col-md-6">
              <div className="process-card">
                <div className="process-img">
                  <img
                    className="process-1"
                    src="assets/imgs/svg/process-1.svg"
                    alt="image"
                  />
                </div>
                <h4>Setup Workframe</h4>
                <p>
                  Nor again is there anyone who loves or pues or desires to
                  obtain pain of itself, because it is pain, but because
                  occasionally
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="process-card">
                <div className="process-img">
                  <img
                    className="process-1"
                    src="assets/imgs/svg/process-2.svg"
                    alt="image"
                  />
                </div>
                <h4>Download & install</h4>
                <p>
                  Nor again is there anyone who loves or pues or desires to
                  obtain pain of itself, because it is pain, but because
                  occasionally
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="process-card">
                <div className="process-img">
                  <img
                    className="process-1"
                    src="assets/imgs/svg/process-3.svg"
                    alt="image"
                  />
                </div>
                <h4>Payment & Boost</h4>
                <p>
                  Nor again is there anyone who loves or pues or desires to
                  obtain pain of itself, because it is pain, but because
                  occasionally
                </p>
              </div>
            </div>
          </div>
        </div>

        <div data-cues="slideInLeft">
          <img
            className="process-shape-1 moveHorizontal
            "
            src="assets/imgs/svg/process-shape-1.png"
            alt="process-shape "
          />
        </div>
        <div data-cues="slideInLeft">
          <img
            className="process-shape-2 moveHorizontal
            "
            src="assets/imgs/svg/process-shape-2.png"
            alt="process-shape "
          />
          <img
            className="footer-shape"
            src="assets/imgs/shapee/image.png"
            alt="image"
          />
        </div>
      </div>

      <section className="hire__area">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="hire__content">
                <h2
                  className="hire__title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  Do You Have a Project In Your Mind ?
                </h2>
                <Link
                  to="/contact-us"
                  className="cxu-btn-border wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  HIRE US NOW
                </Link>
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/imgs/hero/overlay.png"
          alt="overlay"
          className="overlay"
        />
      </section>
      <Footer />
    </>
  );
}

export default ServicesDetail;
