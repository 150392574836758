import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Header() {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);
  return (
    <>
      <header
        className={scroll ? "header__area-2 sticky" : "header__area-2"}
        id="header__sticky"
      >
        <div className="menu-2-bg"></div>
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="header__inner-2">
                <div className="header__logo-2">
                  <Link to="/">
                    <img
                      src="assets/imgs/logo/logooo (1).png"
                      alt="Logo"
                      className="logo w-100"
                    />
                    <img
                      src="assets/imgs/logo/logo-8.png"
                      alt="Logo"
                      className="sticky-logo   w-100"
                    />
                  </Link>
                </div>

                <div className="header__right-2">
                  <div className="header__info-2">
                    <ul className="email">
                      <li>
                        <i className="fa-solid fa-envelope"></i>
                        <a href="mailto:Support@appdelivers.com">
                          Support@appdelivers.com
                        </a>
                      </li>
                    </ul>
                    <div className="address">
                      <p>
                        <i className="fa-solid fa-house"></i> 3107 Bartlett
                        Avenue Southfield
                      </p>
                    </div>
                    <ul className="header__social-2">
                      <li>
                        <a
                          href="https://www.facebook.com/appdelivers"
                          target="_blank"
                        >
                          <i className="fa-brands fa-facebook-f"></i>
                        </a>
                      </li>

                      <li>
                        <a href="#">
                          <i className="fa-brands fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa-brands fa-linkedin"></i>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="header__menu-2">
                    <div className="main-menu menu-1">
                      <ul>
                        <li className="">
                          <Link to="/">Home</Link>
                        </li>
                        <li className="">
                          <Link to="/about-us">About Us</Link>
                        </li>
                        <li className="has-dropdown">
                          <Link to="/services">Services</Link>

                          <ul className="main-dropdown">
                            <div className="row">
                              <div className="col-6">
                                <li className="">
                                  <Link to="/android-app-development">
                                    Android App Development
                                  </Link>
                                </li>
                              </div>
                              <div className="col-6">
                                <li className="">
                                  <Link to="/ios-app-development">
                                    IOS App Development
                                  </Link>
                                </li>
                              </div>
                              <div className="col-6">
                                <li className="">
                                  <Link to="/react-app-development">
                                    React Development
                                  </Link>
                                </li>
                              </div>
                              <div className="col-6">
                                <li className="">
                                  <Link to="/website-app-development">
                                    Website Development
                                  </Link>
                                </li>
                              </div>
                              <div className="col-6">
                                <li className="">
                                  <Link to="/data-science-development">
                                    Data Science & Analysis
                                  </Link>
                                </li>
                              </div>
                              <div className="col-6">
                                <li className="">
                                  <Link to="/machine-learning-development">
                                    AI & Machine Learning
                                  </Link>
                                </li>
                              </div>
                              <div className="col-6">
                                <li className="">
                                  <Link to="/games-development">
                                    2D & 3D Games
                                  </Link>
                                </li>
                              </div>
                              <div className="col-6">
                                <li className="">
                                  <Link to="/blockchain-development">
                                    Blockchain & Metavarse
                                  </Link>
                                </li>
                              </div>
                              <div className="col-6">
                                <li className="">
                                  <Link to="/admin-app-development">
                                    SAAS, CMS, & Admin Panel
                                  </Link>
                                </li>
                              </div>
                              <div className="col-6">
                                <li className="">
                                  <Link to="/services">
                                    Technology Impact on Earth & Environment
                                  </Link>
                                </li>
                              </div>
                            </div>
                          </ul>
                        </li>
                        <li className="">
                          <Link to="/portfolio">Portfolio</Link>
                        </li>
                        {/* <li className="">
                          <Link to="/teams">Teams</Link>
                        </li> */}
                        <li className="">
                          <Link to="/blogs">Blogs</Link>
                        </li>{" "}
                        <button
                          className={
                            scroll ? "menu-icon mt-md-4" : "menu-icon "
                          }
                          data-bs-toggle="offcanvas"
                          href="#offcanvasExample"
                          role="button"
                          aria-controls="offcanvasExample"
                          id="menu-icon"
                        >
                          <i className="fa-solid fa-bars"></i>
                        </button>
                        <Link to="/contact-us" className="cxu-btn-border">
                          Contact Us
                        </Link>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div class="offcanvas-body">
          <div className="offcanvas__area showed">
            <div className="offcanvas__inner">
              <div className="offcanvas__top">
                <img src="assets/imgs/logo/logo.png" alt="Logo" />
                <button
                  type="button"
                  className="me-2"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <i className="fa-solid fa-xmark"></i>
                </button>
              </div>

              <div className="offcanvas__menu">
                <div className="offcanvas-menu">
                  <ul>
                    <li className="active">
                      <Link to="/" className="">
                        <i class="fa-solid fa-house mx-4"></i>Home
                      </Link>
                    </li>
                    <li className="">
                      <Link to="/about-us">
                        <i class="fa-solid fa-address-card mx-4"></i>About Us
                      </Link>
                    </li>
                    <li>
                      <Link to="/services">
                        <i class="fa-solid fa-list-check mx-4"></i>Services
                      </Link>
                    </li>

                    <li className="">
                      <Link to="/portfolio">
                        <i class="fa-solid fa-briefcase mx-4"></i>Portfolio
                      </Link>
                    </li>
                    {/* <li className="">
                      <Link to="/teams">
                        <i class="fa-solid fa-user-plus mx-4"></i>Teams
                      </Link>
                    </li> */}
                    <li className="">
                      <Link to="/blogs">
                        <i class="fa-solid fa-blog mx-4"></i>Blogs
                      </Link>
                    </li>
                    <li className="">
                      <Link to="/contact-us">
                        <i class="fa-solid fa-phone mx-4"></i>Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
