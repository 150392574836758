import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function AboutUs() {
  return (
    <>
      <section className="about__area pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 d-flex justify-content-center align-items-center">
              <div className="">
                <img
                  src="assets/imgs/about/about-1.png"
                  alt="Image"
                  className="image moveVertical"
                />
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
              <div className="about__right  ">
                <div className="about__right-inner ">
                  <h2
                    className="sec-sub-title wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.2s"
                  >
                    WHO WE ARE
                  </h2>
                  <h3
                    className="sec-title wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    We're a dynamic team of creatives people
                  </h3>
                  <p
                    className="wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    There are many variations of passages of Lorem Ipsum
                    available, the majority have suffered alteration in some
                    form, by injected humour. randomised words which don't look
                    even slightly believable. It uses a dictionary of over 200
                    Latin words, combined with the majority have suffered
                    alteration in some form, by injected humour. randomised
                    words which don't look even slightly believable.
                  </p>
                  <ul
                    className="list-check wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    <li>Business Solutions</li>
                    <li>Creative Conceptions</li>
                    <li>Corporate Relationship</li>
                    <li>Mission & Vission</li>
                  </ul>
                  <Link
                    to="/about-us"
                    className="cxu-btn-border-2 wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    READ MORE
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutUs;
