import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AboutUsDetail from "./components/aboutUsDetail";
import Blog from "./components/blog";
import BlogDetail from "./components/blogDetail";
import ScrollToTop from "./components/commonComponents/scrollToTop";
import Contact from "./components/contact";
import Home from "./components/home";

import Portfolio from "./components/portfolio";
import PortfolioDetail from "./components/portfolioDetail";
import PrivacyPolicy from "./components/privacyPolicy";
import AdminServices from "./components/services/adminServices";
import AIServices from "./components/services/aiServices";
import AndroidServices from "./components/services/androidServices";
import BlockChainServices from "./components/services/blockChainServices";
import DataServices from "./components/services/dataServices";
import GameServices from "./components/services/gameServices";
import IosServices from "./components/services/iosServices";
import ReactServices from "./components/services/reactServices";
import WebServices from "./components/services/webServices";
import ServicesDetail from "./components/servicesDetail";
import Team from "./components/team";
import TermCondition from "./components/termCondition";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUsDetail />} />
        <Route path="/services" element={<ServicesDetail />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/portfolio-detail" element={<PortfolioDetail />} />
        <Route path="/blogs" element={<Blog />} />
        <Route path="/blog-details" element={<BlogDetail />} />
        <Route path="/teams" element={<Team />} />
        <Route path="/term-conditions" element={<TermCondition />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/android-app-development" element={<AndroidServices />} />
        <Route path="/ios-app-development" element={<IosServices />} />
        <Route path="/website-app-development" element={<WebServices />} />
        <Route path="/react-app-development" element={<ReactServices />} />
        <Route path="/data-science-development" element={<DataServices />} />
        <Route path="/machine-learning-development" element={<AIServices />} />
        <Route
          path="/blockchain-development"
          element={<BlockChainServices />}
        />
        <Route path="/games-development" element={<GameServices />} />
        <Route path="/admin-app-development" element={<AdminServices />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
