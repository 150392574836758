import React from "react";
import { Link } from "react-router-dom";
import Footer from "./commonComponents/footer";
import Header from "./commonComponents/header";
import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function Portfolio() {
  return (
    <>
      <Header />

      <section className="breadcrumb__area">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__content">
                <h1
                  className="breadcrumb__title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.15s"
                >
                  Our Portfolio
                </h1>
                <p
                  className="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.3s"
                >
                  <Link to="/">Home</Link>
                  <span>Our Portfolio</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/imgs/bg/cyber_banner_image.svg"
          alt="overlay"
          className="overlay"
        />
      </section>

      <section className=" pt-70 pb-70 ">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 text-center">
              <div className="sec-title-wrap">
                <h2
                  className="sec-sub-title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  Our Work
                </h2>
                <h3
                  className="sec-title-2 wow animate__animated animate__fadeInUp "
                  data-wow-delay="0.4s"
                >
                  Our recent case studies
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6  mb-xxl-0 mb-xl-0 mb-lg-0 mb-md-5 mb-sm-0 mb-0">
              <div className="about__right-2 pt-xxl-5 pt-xl-5 pt-lg-0 pt-md-0">
                <div className="about__right-inner-2 pt-xxl-5 pt-xl-5 pt-lg-0 pt-md-0">
                  <h2
                    className="sec-sub-title wow animate__animated animate__fadeInUp mt-xxl-0 mt-xl-0 mt-lg-5 mt-md-5 mt-sm-0 mt-0
                   "
                    data-wow-delay="0.3s"
                  >
                    Blockchain
                  </h2>
                  <h3
                    className="sec-title wow animate__animated animate__fadeInUp "
                    data-wow-delay="0.4s"
                  >
                    Backup Blockchain & Crypto
                  </h3>
                  <p
                    className="wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. Lorem Ipsum is simply dummy text of the
                    printing and typesetting industry.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mt-xxl-0 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-4 mt-4">
              <div className="sotcox-project-item sotcox-project-item__style-2">
                <div className="content">
                  <h4 className="title">
                    <Link to="">Backup Blockchain & Crypto..</Link>
                  </h4>
                  <Link to="" className="inline-btn">
                    <i className="fa fa-arrow-right"></i>
                  </Link>
                </div>
                <OwlCarousel
                  className="banner_slider owl-theme"
                  autoplay={true}
                  autoplayHoverPause={false}
                  autoplayTimeout={4000}
                  dots={true}
                  autoHeight={true}
                  loop={true}
                  nav={true}
                  items={1}
                >
                  <div className="thumb item">
                    <img src="assets/imgs/portfolio/project-2-1.jpg" alt="" />
                  </div>
                  <div className="thumb item">
                    <img src="assets/imgs/portfolio/project-2-2.jpg" alt="" />
                  </div>
                  <div className="thumb item">
                    <img src="assets/imgs/portfolio/project-2-3.jpg" alt="" />
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mt-xxl-0 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-4 mt-4">
              <div className="sotcox-project-item sotcox-project-item__style-2">
                <div className="content">
                  <h4 className="title">
                    <Link to="">App Design For Local Agency ..</Link>
                  </h4>
                  <Link to="" className="inline-btn">
                    <i className="fa fa-arrow-right"></i>
                  </Link>
                </div>
                <OwlCarousel
                  className="banner_slider owl-theme"
                  autoplay={true}
                  autoplayHoverPause={false}
                  autoplayTimeout={4000}
                  dots={true}
                  autoHeight={true}
                  loop={true}
                  nav={true}
                  items={1}
                >
                  <div className="thumb item">
                    <img src="assets/imgs/portfolio/project-2-2.jpg" alt="" />
                  </div>
                  <div className="thumb item">
                    <img src="assets/imgs/portfolio/project-2-3.jpg" alt="" />
                  </div>
                  <div className="thumb item">
                    <img src="assets/imgs/portfolio/project-2-4.jpg" alt="" />
                  </div>
                </OwlCarousel>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6  mb-xxl-0 mb-xl-0 mb-lg-0 mb-md-5 mb-sm-0 mb-0  ">
              <div className="about__right-2 pt-xxl-5 pt-xl-5 pt-lg-0 pt-md-0">
                <div className="about__right-inner-2 pt-xxl-5 pt-xl-5 pt-lg-0 pt-md-0">
                  <h2
                    className="sec-sub-title wow animate__animated animate__fadeInUp mt-xxl-0 mt-xl-0 mt-lg-5 mt-md-5 mt-sm-5 mt-5
                   "
                    data-wow-delay="0.3s"
                  >
                    App management
                  </h2>
                  <h3
                    className="sec-title wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    App Design For Local Agency
                  </h3>
                  <p
                    className="wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. Lorem Ipsum is simply dummy text of the
                    printing and typesetting industry.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-xxl-0 mb-xl-0 mb-lg-0 mb-md-5 mb-sm-0 mb-0">
              <div className="about__right-2 pt-xxl-5 pt-xl-5 pt-lg-0 pt-md-0">
                <div className="about__right-inner-2 pt-xxl-5 pt-xl-5 pt-lg-0 pt-md-0">
                  <h2
                    className="sec-sub-title wow animate__animated animate__fadeInUp mt-xxl-0 mt-xl-0 mt-lg-5 mt-md-5 mt-sm-4 mt-4
                   "
                    data-wow-delay="0.3s"
                  >
                    Ui/Ux Design
                  </h2>
                  <h3
                    className="sec-title wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    The New In Upcoming Design Think?
                  </h3>
                  <p
                    className="wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. Lorem Ipsum is simply dummy text of the
                    printing and typesetting industry.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mt-xxl-0 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-4 mt-4">
              <div className="sotcox-project-item sotcox-project-item__style-2">
                <div className="content">
                  <h4 className="title">
                    <Link to="">The New In Upcoming Design Think?</Link>
                  </h4>
                  <Link to="" className="inline-btn">
                    <i className="fa fa-arrow-right"></i>
                  </Link>
                </div>
                <OwlCarousel
                  className="banner_slider owl-theme"
                  autoplay={true}
                  autoplayHoverPause={false}
                  autoplayTimeout={4000}
                  dots={true}
                  autoHeight={true}
                  loop={true}
                  nav={true}
                  items={1}
                >
                  <div className="thumb item">
                    <img src="assets/imgs/portfolio/project-2-3.jpg" alt="" />
                  </div>
                  <div className="thumb item">
                    <img src="assets/imgs/portfolio/project-2-4.jpg" alt="" />
                  </div>
                  <div className="thumb item">
                    <img src="assets/imgs/portfolio/project-2-1.jpg" alt="" />
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mt-xxl-0 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-4 mt-4">
              <div className="sotcox-project-item sotcox-project-item__style-2">
                <div className="content">
                  <h4 className="title">
                    <Link to="">Branding For American IT..</Link>
                  </h4>
                  <Link to="" className="inline-btn">
                    <i className="fa fa-arrow-right"></i>
                  </Link>
                </div>
                <OwlCarousel
                  className="banner_slider owl-theme"
                  autoplay={true}
                  autoplayHoverPause={false}
                  autoplayTimeout={4000}
                  dots={true}
                  autoHeight={true}
                  loop={true}
                  nav={true}
                  items={1}
                >
                  <div className="thumb item">
                    <img src="assets/imgs/portfolio/project-2-4.jpg" alt="" />
                  </div>
                  <div className="thumb item">
                    <img src="assets/imgs/portfolio/project-2-3.jpg" alt="" />
                  </div>
                  <div className="thumb item">
                    <img src="assets/imgs/portfolio/project-2-2.jpg" alt="" />
                  </div>
                </OwlCarousel>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6  mb-xxl-0 mb-xl-0 mb-lg-0 mb-md-5 mb-sm-0 mb-0">
              <div className="about__right-2 pt-xxl-5 pt-xl-5 pt-lg-0 pt-md-0">
                <div className="about__right-inner-2 pt-xxl-5 pt-xl-5 pt-lg-0 pt-md-0">
                  <h2
                    className="sec-sub-title wow animate__animated animate__fadeInUp mt-xxl-0 mt-xl-0 mt-lg-5 mt-md-5 mt-sm-4 mt-4
                   "
                    data-wow-delay="0.3s"
                  >
                    Machine Learning
                  </h2>
                  <h3
                    className="sec-title wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    Branding For American IT
                  </h3>
                  <p
                    className="wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. Lorem Ipsum is simply dummy text of the
                    printing and typesetting industry.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 ">
              <div className="about__right-2 pt-xxl-5 pt-xl-5 pt-lg-0 pt-md-0">
                <div className="about__right-inner-2 pt-xxl-5 pt-xl-5 pt-lg-0 pt-md-0">
                  <h2
                    className="sec-sub-title wow animate__animated animate__fadeInUp mt-xxl-0 mt-xl-0 mt-lg-5 mt-md-5 mt-sm-5 mt-5
                   "
                    data-wow-delay="0.3s"
                  >
                    Data Science & Data Analysis
                  </h2>
                  <h3
                    className="sec-title wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    Software License Management
                  </h3>
                  <p
                    className="wow animate__animated animate__fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. Lorem Ipsum is simply dummy text of the
                    printing and typesetting industry.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mt-xxl-0 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-4 mt-4">
              <div className="sotcox-project-item sotcox-project-item__style-2">
                <div className="content">
                  <h4 className="title">
                    <Link to="">Software License Management....</Link>
                  </h4>
                  <Link to="" className="inline-btn">
                    <i className="fa fa-arrow-right"></i>
                  </Link>
                </div>
                <OwlCarousel
                  className="banner_slider owl-theme"
                  autoplay={true}
                  autoplayHoverPause={false}
                  autoplayTimeout={4000}
                  dots={true}
                  autoHeight={true}
                  loop={true}
                  nav={true}
                  items={1}
                >
                  <div className="thumb item">
                    <img src="assets/imgs/portfolio/project-2-6.jpg" alt="" />
                  </div>
                  <div className="thumb item">
                    <img src="assets/imgs/portfolio/project-2-2.jpg" alt="" />
                  </div>
                  <div className="thumb item">
                    <img src="assets/imgs/portfolio/project-2-3.jpg" alt="" />
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Portfolio;
