import React, { useRef } from "react";
import { Link } from "react-router-dom";
import Footer from "./commonComponents/footer";
import Header from "./commonComponents/header";
import emailjs from "@emailjs/browser";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

function Contact() {
  const form = useRef();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange", //"onBlur"
  });

  const sendEmail = (data) => {
    emailjs
      .sendForm(
        "service_f84jkzi",
        "template_kc6rwae",
        form.current,
        "B6cpfk6eucerf0fKl"
      )
      .then(
        (result) => {
          console.log(result.text);
          Swal.fire({
            icon: "success",
            title: "Thank you for getting in touch!",
            text: "We appreciate you contacting us. One of our colleagues will get back in touch with you soon!Have a great day!",

            width: 500,

            showCloseButton: true,
            showConfirmButton: false,
          });
          reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <>
      <Header />

      <section className="breadcrumb__area">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__content">
                <h1 className="breadcrumb__title">Contact Us</h1>
                <p>
                  <Link to="/">Home</Link> <span>Contact Us</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/imgs/bg/cyber_banner_image.svg"
          alt="overlay"
          className="overlay"
        />
      </section>

      <section className="contact__area contact__form business-experience">
        <div className="contact__top pt-70 pb-70 ">
          <div className="container">
            <h2
              className="sec-sub-title wow animate__animated animate__fadeInUp"
              data-wow-delay="0.2s"
            >
              Let's Talk
            </h2>
            <h3
              className="sec-title-2 wow animate__animated animate__fadeInUp"
              data-wow-delay="0.4s"
            >
              We’re Happy To Talk To You
            </h3>
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6  d-flex justify-content-center">
                <img
                  src="assets/imgs/about/service-img-2.png"
                  className="moveVertical w-75"
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 ">
                <h2 className="service__title mt-xxl-0 mt-xl-0 mt-lg-0 mt-md-5 mt-sm-5 mt-5">
                  Get in touch
                </h2>
                <form ref={form} onSubmit={handleSubmit(sendEmail)}>
                  <div className="input-grp">
                    <div>
                      <input
                        type="text"
                        placeholder="Your Name*"
                        name="name"
                        {...register("name", {
                          required: true,
                        })}
                      />
                      {errors?.name && (
                        <p className="form-error">This field is required</p>
                      )}
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Type Your Email"
                        name="email"
                        id="email"
                        {...register("email", {
                          required: "This field is required",
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Invalid email address",
                          },
                        })}
                      />

                      {errors?.email && (
                        <p className="form-error ">{errors.email.message}</p>
                      )}
                    </div>
                  </div>
                  <input
                    type="text"
                    name="subject"
                    placeholder="Write Subject"
                    {...register("subject", {
                      required: true,
                    })}
                  />
                  {errors?.subject && (
                    <p className="form-error">This field is required</p>
                  )}
                  <textarea
                    name="message"
                    rows="3"
                    placeholder="Write Your Messages"
                    {...register("message", {
                      required: true,
                    })}
                  ></textarea>
                  {errors?.message && (
                    <p className="form-error">This field is required</p>
                  )}
                  <button className="cxu-btn-border-2 " type="submit">
                    Send Message
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

        <img
          className="footer-shape-2"
          src="assets/imgs/shapee/footer-shape.png"
          alt="image"
        />
      </section>
      <div
        className="stocox-support-secttion pt-120 pb-120"
        style={{ background: "#fff", position: "relative" }}
      >
        <div className="container">
          <h2
            className="sec-sub-title text-center wow animate__animated animate__fadeInUp"
            data-wow-delay="0.2s"
          >
            SUPPORT CENTER
          </h2>
          <h3
            className="sec-title-2 text-center wow animate__animated animate__fadeInUp"
            data-wow-delay="0.4s"
          >
            We always assist you 24/7
          </h3>
          <div className="row ">
            <div className="col-lg-4 col-md-6 ">
              <div
                className="stocox-support-box wow fadeInUp"
                data-wow-delay="00ms"
                data-wow-duration="800ms"
              >
                <div className="icon">
                  <img src="assets/imgs/icons/support-1.png" alt="" />
                </div>
                <div className="content mt-4">
                  <h4 className="title">Visit Us Daily</h4>
                  <p>1791 Yorkshire Circle 10002,USA</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-xxl-0 mt-xl-0 mt-lg-0 mt-md-0 mt-5">
              <div
                className="stocox-support-box wow fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="800ms"
              >
                <div className="icon">
                  <img src="assets/imgs/icons/support-2.png" alt="" />
                </div>
                <div className="content mt-4">
                  <h4 className="title">Contact Us</h4>
                  <p>
                    <a href="tel:+91 9354054810">+91 9354054810</a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6  mt-xxl-0 mt-xl-0 mt-lg-0 mt-md-5 mt-5">
              <div
                className="stocox-support-box wow fadeInUp"
                data-wow-delay="400ms"
                data-wow-duration="800ms"
              >
                <div className="icon">
                  <img src="assets/imgs/icons/support-3.png" alt="" />
                </div>
                <div className="content mt-4">
                  <h4 className="title">Email Us</h4>
                  <p>
                    <a href="mailto:Support@appdelivers.com">
                      Support@appdelivers.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          className="footer-shape"
          src="assets/imgs/shapee/image.png"
          alt="image"
        />
      </div>

      <div className="map">
        <div className="row">
          <div className="col-md-4">
            <iframe
              height="300"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d58408.97484139959!2d90.33915680783855!3d23.798644768568714!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c102e2ece5bb%3A0x446e9dc895326a70!2sBangladesh%20National%20Zoo!5e0!3m2!1sen!2sbd!4v1671466814402!5m2!1sen!2sbd"
            ></iframe>
          </div>
          <div className="col-md-4">
            <iframe
              height="300"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d58408.97484139959!2d90.33915680783855!3d23.798644768568714!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c102e2ece5bb%3A0x446e9dc895326a70!2sBangladesh%20National%20Zoo!5e0!3m2!1sen!2sbd!4v1671466814402!5m2!1sen!2sbd"
            ></iframe>
          </div>
          <div className="col-md-4">
            <iframe
              height="300"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d58408.97484139959!2d90.33915680783855!3d23.798644768568714!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c102e2ece5bb%3A0x446e9dc895326a70!2sBangladesh%20National%20Zoo!5e0!3m2!1sen!2sbd!4v1671466814402!5m2!1sen!2sbd"
            ></iframe>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Contact;
