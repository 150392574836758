import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "./commonComponents/footer";
import Header from "./commonComponents/header";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { LikeButton, Provider } from "@lyket/react";

function Blog() {
  const [search, setSearch] = useState("");
  const [nav, setNav] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (nav && search.includes("Android")) {
        navigate("/blog-details");
      } else if (nav && search.includes("React")) {
        navigate("/blog-details");
      } else if (nav) {
        Swal.fire({
          title: "Oops! No Result Found.",
          width: 500,
          imageUrl: "assets/imgs/about/no-data.gif",
          imageWidth: 200,
          imageAlt: "Custom image",
          showCloseButton: true,
          showConfirmButton: false,
        });
        setSearch("");
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [nav]);

  const getSearch = () => {
    if (search) {
      setNav(true);
    } else {
      setNav(false);
    }
  };

  return (
    <>
      <Header />
      <section className="breadcrumb__area">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__content">
                <h1
                  className="breadcrumb__title wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.2s"
                >
                  Recent Blog
                </h1>
                <p
                  className="wow animate__animated animate__fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <Link to="/">Home</Link>
                  <span>Recent Blog</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/imgs/bg/cyber_banner_image.svg"
          alt="overlay"
          className="overlay"
        />
      </section>

      <section className="blog__area-3 pt-100">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="blog__inner-3">
                <div className="blog__items-3">
                  <div className="row">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                      <div
                        className="blog__item mb-4 shadow wow animate__animated animate__fadeInUp"
                        data-wow-delay="0.2s"
                      >
                        <Link to="/blog-details">
                          <img src="assets/imgs/blog/14.png" alt="Blog Image" />
                        </Link>
                        <div className="blog__content">
                          <div className="date mb-0">22 JAN 2020</div>
                          <p className="postedBy">
                            <Link to="">
                              <i className="fa-regular fa-user"></i> Nirob
                              Aronno
                            </Link>
                          </p>
                          <Link to="/blog-details">
                            <h2 className="blog__title">
                              How to Increase Business Through SEM
                            </h2>
                          </Link>
                          <p>
                            We are passionate about our works. Our designers
                            ahead of the curve.
                          </p>
                          <div className="d-flex justify-content-between align-items-center">
                            <Link to="/blog-details" className="link">
                              Read More
                            </Link>
                            <Link className="like " to="">
                              <Provider
                                apiKey="acc0dbccce8e557db5ebbe6d605aaa"
                                theme={{
                                  colors: {
                                    text: "#1b2e59",
                                    primary: "#22c1c3",
                                    highlight: "#00aded",
                                    icon: "#00aded",
                                  },
                                }}
                              >
                                <LikeButton
                                  namespace="my-blog-post"
                                  id="how-to-beat-me-at-chess-4"
                                />
                              </Provider>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                      <div
                        className="blog__item mb-4 shadow wow animate__animated animate__fadeInUp"
                        data-wow-delay="0.4s"
                      >
                        <Link to="/blog-details">
                          <img src="assets/imgs/blog/2.png" alt="Blog Image" />
                        </Link>
                        <div className="blog__content">
                          <div className="date mb-0">22 JAN 2020</div>
                          <p className="postedBy">
                            <Link to="">
                              <i className="fa-regular fa-user"></i> Nirob
                              Aronno
                            </Link>
                          </p>
                          <Link to="/blog-details">
                            <h2 className="blog__title">
                              How to Increase Business Through SEM
                            </h2>
                          </Link>
                          <p>
                            We are passionate about our works. Our designers
                            ahead of the curve.
                          </p>
                          <div className="d-flex justify-content-between align-items-center">
                            <Link to="/blog-details" className="link">
                              Read More
                            </Link>
                            <Link className="like " to="">
                              <Provider
                                apiKey="acc0dbccce8e557db5ebbe6d605aaa"
                                theme={{
                                  colors: {
                                    text: "#1b2e59",
                                    primary: "#22c1c3",
                                    highlight: "#00aded",
                                    icon: "#00aded",
                                  },
                                }}
                              >
                                <LikeButton
                                  namespace="my-blog-post"
                                  id="how-to-beat-me-at-chess-5"
                                />
                              </Provider>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                      <div
                        className="blog__item mb-4 shadow wow animate__animated animate__fadeInUp"
                        data-wow-delay="0.2s"
                      >
                        <Link to="/blog-details">
                          <img src="assets/imgs/blog/3.png" alt="Blog Image" />
                        </Link>
                        <div className="blog__content">
                          <div className="date mb-0">22 JAN 2020</div>
                          <p className="postedBy">
                            <Link to="">
                              <i className="fa-regular fa-user"></i> Nirob
                              Aronno
                            </Link>
                          </p>
                          <Link to="/blog-details">
                            <h2 className="blog__title">
                              How to Increase Business Through SEM
                            </h2>
                          </Link>
                          <p>
                            We are passionate about our works. Our designers
                            ahead of the curve.
                          </p>
                          <div className="d-flex justify-content-between align-items-center">
                            <Link to="/blog-details" className="link">
                              Read More
                            </Link>
                            <Link className="like " to="">
                              <Provider
                                apiKey="acc0dbccce8e557db5ebbe6d605aaa"
                                theme={{
                                  colors: {
                                    text: "#1b2e59",
                                    primary: "#22c1c3",
                                    highlight: "#00aded",
                                    icon: "#00aded",
                                  },
                                }}
                              >
                                <LikeButton
                                  namespace="my-blog-post"
                                  id="how-to-beat-me-at-chess-6"
                                />
                              </Provider>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                      <div
                        className="blog__item mb-4 shadow wow animate__animated animate__fadeInUp"
                        data-wow-delay="0.4s"
                      >
                        <Link to="/blog-details">
                          <img src="assets/imgs/blog/10.png" alt="Blog Image" />
                        </Link>
                        <div className="blog__content">
                          <div className="date mb-0">22 JAN 2020</div>
                          <p className="postedBy">
                            <Link to="">
                              <i className="fa-regular fa-user"></i> Nirob
                              Aronno
                            </Link>
                          </p>
                          <Link to="/blog-details">
                            <h2 className="blog__title">
                              How to Increase Business Through SEM
                            </h2>
                          </Link>
                          <p>
                            We are passionate about our works. Our designers
                            ahead of the curve.
                          </p>
                          <div className="d-flex justify-content-between align-items-center">
                            <Link to="/blog-details" className="link">
                              Read More
                            </Link>
                            <Link className="like " to="">
                              <Provider
                                apiKey="acc0dbccce8e557db5ebbe6d605aaa"
                                theme={{
                                  colors: {
                                    text: "#1b2e59",
                                    primary: "#22c1c3",
                                    highlight: "#00aded",
                                    icon: "#00aded",
                                  },
                                }}
                              >
                                <LikeButton
                                  namespace="my-blog-post"
                                  id="how-to-beat-me-at-chess-7"
                                />
                              </Provider>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                      <div
                        className="blog__item mb-4 shadow wow animate__animated animate__fadeInUp"
                        data-wow-delay="0.2s"
                      >
                        <Link to="/blog-details">
                          <img src="assets/imgs/blog/11.png" alt="Blog Image" />
                        </Link>
                        <div className="blog__content">
                          <div className="date mb-0">22 JAN 2020</div>
                          <p className="postedBy">
                            <Link to="">
                              <i className="fa-regular fa-user"></i> Nirob
                              Aronno
                            </Link>
                          </p>
                          <Link to="/blog-details">
                            <h2 className="blog__title">
                              How to Increase Business Through SEM
                            </h2>
                          </Link>
                          <p>
                            We are passionate about our works. Our designers
                            ahead of the curve.
                          </p>
                          <div className="d-flex justify-content-between align-items-center">
                            <Link to="/blog-details" className="link">
                              Read More
                            </Link>
                            <Link className="like " to="">
                              <Provider
                                apiKey="acc0dbccce8e557db5ebbe6d605aaa"
                                theme={{
                                  colors: {
                                    text: "#1b2e59",
                                    primary: "#22c1c3",
                                    highlight: "#00aded",
                                    icon: "#00aded",
                                  },
                                }}
                              >
                                <LikeButton
                                  namespace="my-blog-post"
                                  id="how-to-beat-me-at-chess-8"
                                />
                              </Provider>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                      <div
                        className="blog__item mb-4 shadow wow animate__animated animate__fadeInUp"
                        data-wow-delay="0.4s"
                      >
                        <Link to="/blog-details">
                          <img src="assets/imgs/blog/12.png" alt="Blog Image" />
                        </Link>
                        <div className="blog__content">
                          <div className="date mb-0">22 JAN 2020</div>
                          <p className="postedBy">
                            <Link to="">
                              <i className="fa-regular fa-user"></i> Nirob
                              Aronno
                            </Link>
                          </p>
                          <Link to="/blog-details">
                            <h2 className="blog__title">
                              How to Increase Business Through SEM
                            </h2>
                          </Link>
                          <p>
                            We are passionate about our works. Our designers
                            ahead of the curve.
                          </p>
                          <div className="d-flex justify-content-between align-items-center">
                            <Link to="/blog-details" className="link">
                              Read More
                            </Link>
                            <Link className="like " to="">
                              <Provider
                                apiKey="acc0dbccce8e557db5ebbe6d605aaa"
                                theme={{
                                  colors: {
                                    text: "#1b2e59",
                                    primary: "#22c1c3",
                                    highlight: "#00aded",
                                    icon: "#00aded",
                                  },
                                }}
                              >
                                <LikeButton
                                  namespace="my-blog-post"
                                  id="how-to-beat-me-at-chess-9"
                                />
                              </Provider>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="blog__pagination">
                    <ul
                      className="wow animate__animated animate__fadeInUp"
                      data-wow-delay="0.6s"
                    >
                      <li className="prev">
                        <i className="fa-solid fa-chevron-left"></i>
                      </li>
                      <li>01</li>
                      <li>02</li>
                      <li className="next">
                        <i className="fa-solid fa-chevron-right"></i>
                      </li>
                    </ul>
                  </div> */}
                </div>

                <div className="blog__sidebar">
                  <div className="sidebar__widgets">
                    <div
                      className="widget__search wow animate__animated animate__fadeInUp"
                      data-wow-delay="0.2s"
                    >
                      <form>
                        <input
                          type="text"
                          name="s"
                          id="search"
                          placeholder="Search Keywords"
                          // onChange={(e) => setSearch(e.target.value)}
                          // value={search}
                        />
                        <Link
                          to=""
                          className="submit"
                          value="Search"
                          // onClick={() => getSearch()}
                        >
                          Search
                        </Link>
                      </form>
                    </div>
                    <div
                      className="widget__category wow animate__animated animate__fadeInUp"
                      data-wow-delay="0.4s"
                    >
                      <h2 className="widget__title pb-3">Categories</h2>
                      <ul>
                        <li>
                          <Link to="">
                            Android App Development <span>(05)</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            iOS App Development <span>(05)</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            React App Development <span>(05)</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            Website/WebApp Development <span>(05)</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            SAAS, CMS, Admin Panel, CRMS <span>(05)</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            Data Science & Data Analysis <span>(05)</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            Artificial Intelligence & Machine Learning
                            <span>(05)</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            2D & 3D Games
                            <span>(05)</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            Blockchain, NFT, & Metavarse
                            <span>(05)</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            Technology Impact on Earth & Environment
                            <span>(05)</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div
                      className="widget__rposts wow animate__animated animate__fadeInUp"
                      data-wow-delay="0.1s"
                    >
                      <h2 className="widget__title">Recent Posts</h2>

                      <div className="recent-items">
                        <div className="recent-item">
                          <div className="img">
                            <Link to="">
                              <img src="assets/imgs/blog/5.png" alt="Image" />
                            </Link>
                          </div>
                          <div className="info">
                            <Link to="">
                              <h2 className="title">
                                A wonderful serenity has taken possession
                              </h2>
                            </Link>
                            <p>28 April 2021</p>
                          </div>
                        </div>
                        <div className="recent-item">
                          <div className="img">
                            <Link to="">
                              <img src="assets/imgs/blog/8.png" alt="Image" />
                            </Link>
                          </div>
                          <div className="info">
                            <Link to="">
                              <h2 className="title">
                                Company launches new software channel
                              </h2>
                            </Link>
                            <p>28 April 2021</p>
                          </div>
                        </div>
                        <div className="recent-item">
                          <div className="img">
                            <Link to="">
                              <img src="assets/imgs/blog/6.png" alt="Image" />
                            </Link>
                          </div>
                          <div className="info">
                            <Link to="">
                              <h2 className="title">
                                A wonderful serenity has taken possession
                              </h2>
                            </Link>
                            <p>28 April 2021</p>
                          </div>
                        </div>
                        <div className="recent-item">
                          <div className="img">
                            <Link to="">
                              <img src="assets/imgs/blog/7.png" alt="Image" />
                            </Link>
                          </div>
                          <div className="info">
                            <Link to="">
                              <h2 className="title">
                                A wonderful serenity has taken possession
                              </h2>
                            </Link>
                            <p>28 April 2021</p>
                          </div>
                        </div>
                        <div className="recent-item">
                          <div className="img">
                            <Link to="">
                              <img src="assets/imgs/blog/8.png" alt="Image" />
                            </Link>
                          </div>
                          <div className="info">
                            <Link to="">
                              <h2 className="title">
                                Company launches new software channel
                              </h2>
                            </Link>
                            <p>28 April 2021</p>
                          </div>
                        </div>
                        <div className="recent-item">
                          <div className="img">
                            <Link to="">
                              <img src="assets/imgs/blog/9.png" alt="Image" />
                            </Link>
                          </div>
                          <div className="info">
                            <Link to="">
                              <h2 className="title">
                                Company launches new software channel
                              </h2>
                            </Link>
                            <p>28 April 2021</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div
                      className="widget__tags wow animate__animated animate__fadeInUp"
                      data-wow-delay="0.2s"
                    >
                      <h2 className="widget__title">Popular Tages</h2>
                      <ul>
                        <li>
                          <Link to="">Business</a>
                        </li>
                        <li>
                          <Link to="">Corporate</a>
                        </li>
                        <li>
                          <Link to="">Financial</a>
                        </li>
                        <li>
                          <Link to="">Web Development</a>
                        </li>
                        <li>
                          <Link to="">UI/UX Research</a>
                        </li>
                        <li>
                          <Link to="">Branding</a>
                        </li>
                        <li>
                          <Link to="">Website</a>
                        </li>
                        <li>
                          <Link to="">Web Plans</a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Blog;
